const BASE = '[CreateReport]';

const CreateReportTypes = {
  CHANGE_ACTIONS: `${BASE} CHANGE_ACTIONS`,

  FETCH_MAIN_STATUS: `${BASE} FETCH_MAIN_STATUS`,
  FETCH_MAIN_STATUS_SUCCESS: `${BASE} FETCH_MAIN_STATUS_SUCCESS`,
  FETCH_MAIN_STATUS_FAIL: `${BASE} FETCH_MAIN_STATUS_FAIL`,

  ADD_MAIN_STATUS: `${BASE} ADD_MAIN_STATUS`,

  FETCH_OBSERVATIONS: `${BASE} FETCH_OBSERVATIONS`,
  FETCH_OBSERVATIONS_SUCCESS: `${BASE} FETCH_OBSERVATIONS_SUCCESS`,
  FETCH_OBSERVATIONS_FAIL: `${BASE} FETCH_OBSERVATIONS_FAIL`,

  FETCH_OBSERVATION_STATUS: `${BASE} FETCH_OBSERVATION_STATUS`,
  FETCH_OBSERVATION_STATUS_SUCCESS: `${BASE} FETCH_OBSERVATION_STATUS_SUCCESS`,
  FETCH_OBSERVATION_STATUS_FAIL: `${BASE} FETCH_OBSERVATION_STATUS_FAIL`,

  ADD_OBSERVATION: `${BASE} ADD_OBSERVATION`,
  REMOVE_OBSERVATION: `${BASE} REMOVE_OBSERVATION`,

  UPDATE_REPORT_DATA: `${BASE} UPDATE_REPORT_DATA`,
  UPDATE_OBSERVATION_MODAL_DATA: `${BASE} UPDATE_OBSERVATION_MODAL_DATA`,

  UPDATE_VEHICLE_INFORMATION: `${BASE} UPDATE_VEHICLE_INFORMATION`,

  FETCH_VEHICLE_PRICE: `${BASE} FETCH_VEHICLE_PRICE`,
  FETCH_VEHICLE_PRICE_SUCCESS: `${BASE} FETCH_VEHICLE_PRICE_SUCCESS`,
  FETCH_VEHICLE_PRICE_FAIL: `${BASE} FETCH_VEHICLE_PRICE_FAIL`,

  DELETE_REPORT: `${BASE} DELETE_REPORT`,
  DELETE_REPORT_SUCCESS: `${BASE} DELETE_REPORT_SUCCESS`,
  DELETE_REPORT_FAIL: `${BASE} DELETE_REPORT_FAIL`,

  FETCH_REPORT_DETAILS: `${BASE} FETCH_REPORT_DETAILS`,
  FETCH_REPORT_DETAILS_SUCCESS: `${BASE} FETCH_REPORT_DETAILS_SUCCESS`,
  FETCH_REPORT_DETAILS_FAIL: `${BASE} FETCH_REPORT_DETAILS_FAIL`,

  UPLOAD_REPORT_INFORMATION: `${BASE} UPLOAD_REPORT_INFORMATION`,
  UPLOAD_REPORT_INFORMATION_SUCCESS: `${BASE} UPLOAD_REPORT_INFORMATION_SUCCESS`,
  UPLOAD_REPORT_INFORMATION_FAIL: `${BASE} UPLOAD_REPORT_INFORMATION_FAIL`,

  FETCH_MAX_REPORT_NUMBER: `${BASE} FETCH_MAX_REPORT_NUMBER`,
  FETCH_MAX_REPORT_NUMBER_SUCCESS: `${BASE} FETCH_MAX_REPORT_NUMBER_SUCCESS`,
  FETCH_MAX_REPORT_NUMBER_FAIL: `${BASE} FETCH_MAX_REPORT_NUMBER_FAIL`,

  UPLOAD_OBSERVATION_IMAGE: `${BASE} UPLOAD_OBSERVATION_IMAGE`,
  UPLOAD_OBSERVATION_IMAGE_SUCCESS: `${BASE} UPLOAD_OBSERVATION_IMAGE_SUCCESS`,
  UPLOAD_OBSERVATION_IMAGE_FAIL: `${BASE} UPLOAD_OBSERVATION_IMAGE_FAIL`,

  DELETE_REPORT_IMAGES: `${BASE} DELETE_REPORT_IMAGES`,
  DELETE_REPORT_IMAGES_SUCCESS: `${BASE} DELETE_REPORT_IMAGES_SUCCESS`,
};

export default CreateReportTypes;
