import { Environment } from 'model-environment';
import Models from '../models';

const deconsructRUT = (prevRUT) => {
  let M = 0;
  let S = 1;
  let rut = prevRUT;

  for (; rut; rut = Math.floor(rut / 10)) S = (S + (rut % 10) * (9 - (M += 1 % 6))) % 11;

  return {
    M,
    S,
  };
};

const verifierDigit = (rut) => {
  const { S } = deconsructRUT(rut);
  return S ? S - 1 : 'k';
};

const completeRUT = (rut) => rut.toString()?.replace(/[-.]/g, '').trim();

const testRut = (rut) => {
  const fullRUT = completeRUT(rut);
  const match = /^[0-9]+[0-9kK]{1}$/.test(fullRUT);

  let digv = fullRUT.slice(-1);
  const rutSinDv = fullRUT.slice(0, -1);

  if (digv === 'K') digv = 'k';

  return {
    match,
    fullRUT,
    digv,
    rutSinDv,
  };
};

export const validateTaxNumber = (rut = '') => {
  // if (!rut) return false;

  const { match, rutSinDv, digv } = testRut(rut);

  if (!match) return false;

  return verifierDigit(rutSinDv).toString() === digv.toString();
};

export const onlyKeysWithValue = (obj) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      result[key] = obj[key];
    }
  });
  return result;
};

export const getExtension = (fileName) => fileName.split('.').pop().toLowerCase();

export const removeExtension = (fileName) =>
  fileName.split('.').slice(0, -1).join('.').toLowerCase();

/**
 * @param {Object} objects
 * @returns {Object}
 */
export const reselectObjects = (objects) => new Environment({ objects }, Models).parseDB().objects;

export const normalizeObj = (arr) =>
  arr.reduce((prev, current) => {
    // eslint-disable-next-line no-param-reassign
    prev[current.id] = current;
    return prev;
  }, {});

const converToYoutubeUrl = ({ input, pattern }) => {
  const replacement = 'https://www.youtube.com/embed/$1';

  if (input.includes('/embed')) return input;

  return input.replace(pattern, replacement);
};

const convertToVimeoUrl = ({ input, pattern }) => {
  const replacement = 'https://player.vimeo.com/video/$1';

  if (input.includes('https://player')) return input;

  return input.replace(pattern, replacement);
};

const matchYoutubePattern = ({ input }) => {
  const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
  if (pattern.test(input)) {
    return converToYoutubeUrl({ input, pattern });
  }

  return null;
};

const matchVimeoPattern = ({ input }) => {
  const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
  if (pattern.test(input)) {
    return convertToVimeoUrl({ input, pattern });
  }

  return null;
};

const switchYoutubeToVimeo = ({ isYoutube, input }) => {
  if (isYoutube) {
    return matchYoutubePattern({ input });
  }

  return matchVimeoPattern({ input });
};

const validateInput = (input) => {
  const youtubePrefix = ['youtube', 'youtu', 'youtube.com', 'youtu.be'];
  // const isYoutube = input.includes('youtube') || input.includes('youtu.be');
  const isYoutube = youtubePrefix.some((prefix) => input.includes(prefix));
  const isVimeo = input.includes('vimeo');

  return {
    isYoutube,
    isVimeo,
    noMatch: !isYoutube && !isVimeo,
  };
};

export const convertVideo = (input) => {
  const validation = validateInput(input);
  const { noMatch, isYoutube } = validation;

  if (noMatch) return null;

  return switchYoutubeToVimeo({ isYoutube, input });
};

export const captureEnterEvent = (evt, callback) => {
  if (evt.key === 'Enter') {
    callback();
  }
};

const paramRegexResult = (url, param) => {
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
  const result = regex.exec(url);

  return result ? result[2] : null;
};

export const getParameterByName = (param = '', url = window.location.search) => {
  const path = paramRegexResult(url, param);

  if (path) {
    return decodeURIComponent(path.replace(/\+/g, ' '));
  }

  return '';
};

const validateEntry = ({ entries, more }) => entries[0].isIntersecting && more;

const disconectObserver = ({ observer }) => {
  // Disconnect observer set on previous last element
  if (observer.current) observer.current.disconnect();
};

const connectObserver = ({ observer, element }) => {
  // observe/monitor last element
  if (element) observer.current.observe(element);
};

// TODO: Refactor for multiple usage
export const handleObserver = ({ element, observer, more, setPage }) => {
  // Element is the React element being referenced

  disconectObserver({ observer });

  // if there's no more data to be fetched, don't set new observer
  if (!more) return;

  // Set new observer
  observer.current = new IntersectionObserver((entries) => {
    // increase page number when element enters (is intersecting with) viewport.
    // This triggers the pagination hook to fetch more items in the new page
    if (validateEntry({ entries, more })) setPage((prev) => prev + 1);
  });

  connectObserver({ observer, element });
};
