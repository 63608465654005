export const mainStatusModalSelector = (state) => state.createReport.actions.mainStatusModal;

export const observationsModalSelector = (state) => state.createReport.actions.observationsModal;

export const vehicleNotFoundModalSelector = (state) =>
  state.createReport.actions.vehicleNotFoundModal;

export const newMileageModalSelector = (state) => state.createReport.actions.newMileageModal;

export const reportDataSelector = (state) => state.createReport.reportData;

export const reportDetailsFetchedSelector = (state) =>
  state.createReport.actions.reportDetailsFetched;

export const uploadingReportModalSelector = (state) =>
  state.createReport.actions.uploadingReportInformationModal;

export const uploadingImageModalSelector = (state) =>
  state.createReport.actions.uploadingImageModal;

const CreateReportSelector = {
  mainStatusModal: mainStatusModalSelector,
  observationsModal: observationsModalSelector,
  reportData: reportDataSelector,
  vehicleNotFoundModal: vehicleNotFoundModalSelector,
  newMileageModal: newMileageModalSelector,
  reportDetailsFetched: reportDetailsFetchedSelector,
  uploadingReportModal: uploadingReportModalSelector,
  uploadingImageModal: uploadingImageModalSelector,
};

export default CreateReportSelector;
