/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { vehicleNotFoundModalSelector } from '../createReport.selector';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function VehicleNotFoundModal(props) {
  const { setVehicleNotFoundModal, navigate, isEditPage, isCreationPage } = props;
  const openVehicleNotFoundModal = useSelector(vehicleNotFoundModalSelector);

  const closeVehicleNotFoundModal = () => {
    if (isCreationPage) {
      navigate('/');
    } else {
      navigate('/search');
    }
    setVehicleNotFoundModal(false);
  };

  return (
    <Dialog
      open={openVehicleNotFoundModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => closeVehicleNotFoundModal()}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grid container justifyContent="center" sx={{ paddingTop: '25px' }}>
          <Grid container justifyContent="center">
            {!isEditPage && (
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                INFORMACIÓN DEL VEHÍCULO NO ENCONTRADA
              </Typography>
            )}
            {isEditPage && (
              <>
                <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }}>
                  SE HA INTERRUMPIDO EL FLUJO DE COPIAR FICHA
                </Typography>
                <Typography align="center" sx={{ marginTop: '10px', marginBottom: '5px' }}>
                  Si se estaba copiando una ficha, la nueva información ingresada no se ha guardado,
                  quedando guardada en SAP sólo con la información de su versión anterior.
                </Typography>
                <Typography align="center">
                  Por favor eliminar esta copia y luego repetir el proceso.
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: '30px' }}>
        <Grid container item sm={12} md={12} lg={12} justifyContent="center">
          <Button onClick={closeVehicleNotFoundModal} variant="contained" color="secondary">
            Volver atrás
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default VehicleNotFoundModal;
