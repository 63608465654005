import { put, spawn } from 'redux-saga/effects';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';

const { takeLatest } = require('redux-saga/effects');
const {
  fetchVehicleInformation,
  fetchVehicleInformationSuccess,
  fetchVehicleInformationFail,
  clearVehicleInformation,
} = require('./searchVehicle.actions');

function* fetchVehicleInformationSaga() {
  yield takeLatest(fetchVehicleInformation, function* fetchVehicleInformationToApi(action) {
    const { formattedLicensePlate } = action.payload;
    const vehicleInformation = yield fetch(
      `api/v1/vehicle_reports/vehicle-info/${formattedLicensePlate}`,
      {
        method: 'get',
        mode: 'no-cors',
      },
    );
    const response = yield vehicleInformation.json();
    if (!response.error) {
      yield defaultSuccessToast({ message: '¡Equipo encontrado con éxito!' });
      yield put(fetchVehicleInformationSuccess(response));
    } else {
      yield defaultErrorToast({ message: response.error.msg });
      yield put(clearVehicleInformation());
      yield put(fetchVehicleInformationFail());
    }
  });
}

export default function* SearchVehicleSaga() {
  yield spawn(fetchVehicleInformationSaga);
}
