import React from 'react';
// import { Navigate } from 'react-router-dom';

function NotFoundPage() {
  // <Navigate to="/" />

  return <div>Page not found</div>;
}

export default NotFoundPage;
