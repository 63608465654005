const navbarStyles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: (theme) => theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    marginRight: (theme) => theme.spacing(2),
    fontFamily: 'Roboto',
  },
  boldText: {
    fontWeight: 'bold',
  },
};

export default navbarStyles;
