/* eslint-disable complexity */
/* eslint-disable max-depth */
import { Button, Grid, Typography } from '@mui/material';
import { userSelector } from 'content/features/authentication/user/user.selector';
import MainStatusModal from 'content/features/create-report/components/MainStatusModal';
import MenuToggleButton from 'content/features/create-report/components/MenuToggleButton';
import NewMileageModal from 'content/features/create-report/components/NewMileageModal';
import ObservationModal from 'content/features/create-report/components/ObservationModal';
import PrincStateDescription from 'content/features/create-report/components/PrincStateDescription';
import ReportSummary from 'content/features/create-report/components/ReportSummary';
import VehicleInformation from 'content/features/create-report/components/VehicleInformation';
import VehicleNotFoundModal from 'content/features/create-report/components/VehicleNotFoundModal';
import {
  addMainStatus,
  addObservation,
  changeActions,
  deleteReportImages,
  deleteReportSap,
  fetchMainStatus,
  fetchMaxReportNumber,
  fetchObservationStatus,
  fetchObservations,
  fetchReportDetails,
  fetchVehiclePrice,
  updateReportData,
  updateVehicleInformationCreateReport,
  uploadReportInformation,
} from 'content/features/create-report/createReport.actions';
import {
  reportDataSelector,
  reportDetailsFetchedSelector,
  uploadingImageModalSelector,
  uploadingReportModalSelector,
} from 'content/features/create-report/createReport.selector';
import LoadingModal from 'content/shared/LoadingModal/LoadingModal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { normalizeObj } from 'utils/helper';
import { objectsSelector } from 'utils/reducer/objects.selector';

function CreateReport() {
  const [expandAll, setExpandAll] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const isEditPage = location.pathname.split('/').slice(-1)[0] === 'edit';
  const isDetailsPage = location.pathname.split('/').slice(-1)[0] === 'details';
  const isCreationPage = location.pathname.split('/').slice(-1)[0] === 'create';

  const objectsState = useSelector(objectsSelector);
  const reportData = useSelector(reportDataSelector);
  const uploadingDataModal = useSelector(uploadingReportModalSelector);
  const reportDetailsFetched = useSelector(reportDetailsFetchedSelector);
  const uploadingImgModal = useSelector(uploadingImageModalSelector);
  const user = useSelector(userSelector);
  const allMainStatus = Object.values(objectsState.mainStatus);
  const vehicleInformation = Object.values(objectsState.vehicleInformation)[0];
  const mainStatusSelected = Object.values(objectsState.mainStatusSelected);
  const allObservations = Object.values(objectsState.observations);
  const allObservationStatus = Object.values(objectsState.observationStatus);
  const observationsSelected = Object.values(objectsState.observationsSelected);
  const { preLoadedInformation } = objectsState;

  const username = user?.email?.split('@')[0].replace(/[^a-zA-Z0-9 ]/g, '');
  const userId = user?.id;

  const handleExpandAccordions = () => {
    setExpandAll(!expandAll);
  };

  const isSomeMainStatusEmpty = mainStatusSelected.some((mS) => mS === '');

  const setMainStatusModal = (boolean) => {
    dispatch(changeActions({ mainStatusModal: boolean }));
  };

  const setNewMileageModal = (boolean) => {
    dispatch(changeActions({ newMileageModal: boolean }));
  };

  const setObservationsModal = (boolean) => {
    dispatch(changeActions({ observationsModal: boolean }));
  };

  const setVehicleNotFoundModal = (boolean) => {
    dispatch(changeActions({ vehicleNotFoundModal: boolean }));
  };

  const updateVehicleNewMileage = (newMileage) => {
    dispatch(updateVehicleInformationCreateReport({ vehicleNewMileage: newMileage }));
    dispatch(
      fetchVehiclePrice({
        vehicleInformation: {
          vehicleYear: vehicleInformation.vehicleYear,
          vehicleModel: vehicleInformation.vehicleModel,
          vehicleMileage: Number(newMileage),
          licensePlate: vehicleInformation?.vehicleLicensePlate,
        },
        vehicleVBaja: vehicleInformation.vehicleVBaja,
        toggleNewMileageModal: false,
      }),
    );
  };

  const updateVehicleInformation = (totalDiscounts) => {
    dispatch(updateVehicleInformationCreateReport({ vehicleTotalDiscounts: totalDiscounts }));
  };

  const cancelReportCreation = () => {
    if (isEditPage || isDetailsPage) {
      if (isEditPage) {
        dispatch(
          deleteReportSap({
            report: {
              reportId: vehicleInformation.reportId,
              reportVersion: vehicleInformation.reportVersion,
              vehicleLicensePlate: vehicleInformation?.vehicleLicensePlate,
            },
            userName: username,
          }),
        );
      }
      navigate('/search');
    } else {
      dispatch(
        deleteReportImages({
          reportId: vehicleInformation.reportId,
          reportVersion: vehicleInformation.reportNewVersion
            ? vehicleInformation.reportNewVersion
            : vehicleInformation.reportVersion,
          vehicleLicensePlate: vehicleInformation.vehicleLicensePlate,
        }),
      );
      navigate('/');
    }
  };

  const getObservationType = (typeId) => {
    let result;
    switch (typeId) {
      case 'P':
        result = 'Pintura';
        break;
      case 'E':
        result = 'Estructura';
        break;
      case 'M':
        result = 'Mecánica';
        break;
      case 'T':
        result = 'Tapiz';
        break;
      case 'B':
        result = 'Parabrisas';
        break;
      case 'N':
        result = 'Neumáticos';
        break;
      case 'D':
        result = 'Documentación';
        break;
      case 'A':
        result = 'Accesorios';
        break;
      default:
        console.log('No pertenece a ningún tipo de estado principal');
    }
    return result;
  };

  useEffect(() => {
    dispatch(fetchMainStatus());
    dispatch(fetchObservations());
    dispatch(fetchObservationStatus());
    if (isCreationPage && !vehicleInformation?.reportId && !vehicleInformation?.reportVersion) {
      dispatch(fetchMaxReportNumber());
      dispatch(updateReportData({ commentary: '' }));
    }
    if (isDetailsPage) {
      const { reportId, reportVersion } = params;
      dispatch(
        fetchReportDetails({
          reportId,
          reportVersion,
          userName: username,
        }),
      );
    } else if (!vehicleInformation?.vehicleModel) {
      setVehicleNotFoundModal(true);
    } else {
      dispatch(
        fetchVehiclePrice({
          vehicleInformation: {
            vehicleYear: vehicleInformation?.vehicleYear,
            vehicleModel: vehicleInformation?.vehicleModel,
            vehicleMileage: isEditPage
              ? vehicleInformation?.vehicleMileage
              : vehicleInformation?.vehicleNewMileage,
            licensePlate: vehicleInformation?.vehicleLicensePlate,
          },
          vehicleVBaja: vehicleInformation.vehicleVBaja,
          toggleNewMileageModal: isEditPage,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (isEditPage) {
      const mainStatusToUpdate = {};
      const allMainStatusCopy = [...allMainStatus];
      if (allMainStatusCopy.length) {
        if (!preLoadedInformation?.mainStatus) {
          setVehicleNotFoundModal(true);
        } else {
          Object.values(preLoadedInformation.mainStatus).forEach((item) => {
            const mainStatusToInsert = allMainStatusCopy.filter(
              (mainStatus) =>
                mainStatus.type_id === item.type_id &&
                mainStatus.status_id === Number(item.status_id),
            )[0];
            mainStatusToUpdate[`${item.key}`] = mainStatusToInsert || '';
          });
          dispatch(addMainStatus({ objects: { mainStatusSelected: mainStatusToUpdate } }));
        }
      }
    }
  }, [allMainStatus.length]);

  useEffect(() => {
    if (isDetailsPage) {
      const mainStatusToUpdate = {};
      const allMainStatusCopy = [...allMainStatus];
      if (allMainStatusCopy.length && preLoadedInformation?.mainStatus) {
        Object.values(preLoadedInformation?.mainStatus).forEach((item) => {
          const mainStatusToInsert = allMainStatusCopy.filter(
            (mainStatus) =>
              mainStatus.type_id === item.type_id &&
              mainStatus.status_id === Number(item.status_id),
          )[0];
          mainStatusToUpdate[`${item.key}`] = mainStatusToInsert || '';
        });
        dispatch(addMainStatus({ objects: { mainStatusSelected: mainStatusToUpdate } }));
      }
    }
  }, [reportDetailsFetched]);

  useEffect(() => {
    if (isDetailsPage) {
      const observationsSelectedToUpdate = [];
      const allObservationsCopy = [...allObservations];
      const allObservationStatusCopy = [...allObservationStatus];
      if (
        allObservationStatus.length &&
        allObservations.length &&
        preLoadedInformation.observations
      ) {
        Object.values(preLoadedInformation.observations).forEach((observation) => {
          const observationToInsert = {};
          const observationFiltered = allObservationsCopy.filter(
            (item) =>
              item.type_id === observation.typeId && item.dano_id === Number(observation.danoId),
          )[0];
          const observationStatusFiltered = allObservationStatusCopy.filter(
            (item) => item.status_id === Number(observation.statusId),
          )[0];
          observationToInsert.id = observationFiltered.id;
          observationToInsert.observationType = getObservationType(observationFiltered.type_id);
          observationToInsert.observation = observationFiltered;
          observationToInsert.status = observationStatusFiltered;
          observationToInsert.files = [];
          observationsSelectedToUpdate.push(observationToInsert);
          if (preLoadedInformation.images) {
            Object.values(preLoadedInformation.images).forEach((img) => {
              if (
                img.typeId === observationFiltered.type_id &&
                Number(img.danoId) === Number(observationFiltered.dano_id)
              ) {
                const imgToAppend = {
                  file: {
                    name: img.fileName,
                  },
                  url: `data:image/jpg;base64,${img.base64}`,
                };
                observationToInsert.files.push(imgToAppend);
              }
            });
          }
        });
        dispatch(
          addObservation({
            objects: {
              observationsSelected: normalizeObj(observationsSelectedToUpdate),
            },
          }),
        );
      }
    }
  }, [
    reportDetailsFetched &&
      allObservationStatus.length &&
      allObservations.length &&
      preLoadedInformation.observations,
  ]);

  useEffect(() => {
    if (isEditPage) {
      const observationsSelectedToUpdate = [];
      const allObservationsCopy = [...allObservations];
      const allObservationStatusCopy = [...allObservationStatus];
      if (allObservationStatus.length && allObservations.length) {
        if (!preLoadedInformation?.observations) {
          setVehicleNotFoundModal(true);
        } else {
          Object.values(preLoadedInformation.observations).forEach((observation) => {
            const observationToInsert = {};
            const observationFiltered = allObservationsCopy.filter(
              (item) =>
                item.type_id === observation.typeId && item.dano_id === Number(observation.danoId),
            )[0];

            const observationStatusFiltered = allObservationStatusCopy.filter(
              (item) => item.status_id === Number(observation.statusId),
            )[0];
            observationToInsert.id = observationFiltered.id;
            observationToInsert.observationType = getObservationType(observationFiltered.type_id);
            observationToInsert.observation = observationFiltered;
            observationToInsert.status = observationStatusFiltered;
            observationToInsert.files = [];
            observationsSelectedToUpdate.push(observationToInsert);
            if (preLoadedInformation.images) {
              Object.values(preLoadedInformation.images).forEach((img) => {
                if (
                  img.typeId === observationFiltered.type_id &&
                  Number(img.danoId) === Number(observationFiltered.dano_id)
                ) {
                  const imgToAppend = {
                    file: {
                      name: img.fileName,
                    },
                    url: `data:image/jpg;base64,${img.base64}`,
                  };
                  observationToInsert.files.push(imgToAppend);
                }
              });
            }
          });

          dispatch(
            addObservation({
              objects: {
                observationsSelected: normalizeObj(observationsSelectedToUpdate),
              },
            }),
          );
        }
      }
    }
  }, [allObservationStatus.length && allObservations.length]);

  const sendReportInformation = async (saveStatus) => {
    dispatch(
      uploadReportInformation({
        observationsSelected,
        mainStatusSelected,
        vehicleInformation,
        saveStatus,
        navigate,
        userId,
      }),
    );
  };

  const getReportVersion = () => {
    if (isCreationPage && vehicleInformation?.reportNewVersion) {
      return vehicleInformation.reportNewVersion;
    }
    if (isEditPage || isDetailsPage) {
      return Number(vehicleInformation?.reportVersion);
    }
    return '01';
  };

  return (
    <>
      <Grid
        container
        sx={{ marginLeft: { xs: '6px', sm: '10px' }, marginRight: { xs: '6px', sm: '10px' } }}>
        <Grid item md={12} lg={12} xs={12} sx={{ margin: '40px 0 10px 0' }}>
          <Typography
            variant="h4"
            sx={{ fontSize: { sm: '32px', xs: '24px' }, fontWeight: 'bold' }}>
            Ficha vehículo #{Number(vehicleInformation?.reportId) || ''}-{getReportVersion() || ''}
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <VehicleInformation
            vehicleInformation={vehicleInformation}
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid
          container
          item
          md={12}
          lg={12}
          xs={12}
          justifyContent="right"
          sx={{ marginBottom: '10px' }}>
          <Button variant="contained" color="secondary" onClick={handleExpandAccordions}>
            DESPLEGAR TODO
          </Button>
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="PINTURA"
            photosColumn
            expand={expandAll}
            hasMainState
            mainState={objectsState?.mainStatusSelected?.paintStatus?.description}
            observationsSelected={observationsSelected}
            typeId="P"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="ESTRUCTURA"
            photosColumn
            expand={expandAll}
            hasMainState
            mainState={objectsState?.mainStatusSelected?.structureStatus?.description}
            observationsSelected={observationsSelected}
            typeId="E"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="NEUMÁTICOS"
            photosColumn
            expand={expandAll}
            hasMainState
            mainState={objectsState?.mainStatusSelected?.tiresStatus?.description}
            observationsSelected={observationsSelected}
            typeId="N"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="PARABRISAS"
            photosColumn
            expand={expandAll}
            hasMainState
            mainState={objectsState?.mainStatusSelected?.windshieldStatus?.description}
            observationsSelected={observationsSelected}
            typeId="B"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="DOCUMENTACIÓN"
            photosColumn
            onlyDetails
            expand={expandAll}
            observationsSelected={observationsSelected}
            typeId="D"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="TAPIZ"
            photosColumn
            expand={expandAll}
            hasMainState
            mainState={objectsState?.mainStatusSelected?.tapestryStatus?.description}
            observationsSelected={observationsSelected}
            typeId="T"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="ACCESORIOS"
            photosColumn
            expand={expandAll}
            observationsSelected={observationsSelected}
            typeId="A"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="MECÁNICA"
            photosColumn
            expand={expandAll}
            hasMainState
            mainState={objectsState?.mainStatusSelected?.mechanicsStatus?.description}
            observationsSelected={observationsSelected}
            typeId="M"
            isDetailsPage={isDetailsPage}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <PrincStateDescription
            accordionTitle="COMENTARIOS"
            isCommentaryItem
            reportData={reportData}
            expand={expandAll}
            observationsSelected={observationsSelected}
            typeId="C"
            isDetailsPage={isDetailsPage}
            vehicleInformation={vehicleInformation}
          />
        </Grid>
        <Grid item md={12} lg={12} xs={12}>
          <ReportSummary
            mainStatusSelected={mainStatusSelected}
            vehiclePrice={vehicleInformation?.vehicleBasePrice}
            observationsSelected={observationsSelected}
            updateVehicleInformation={updateVehicleInformation}
            vehicleVBaja={vehicleInformation?.vehicleVBaja}
            dispatch={dispatch}
          />
        </Grid>
        <Grid
          container
          item
          md={12}
          lg={12}
          xs={12}
          justifyContent="center"
          sx={{ marginTop: '10px' }}>
          {!isDetailsPage && (
            <>
              <Grid container item md={6} lg={6} xs={6} justifyContent="center">
                <Button variant="outlined" color="error" onClick={cancelReportCreation}>
                  CANCELAR
                </Button>
              </Grid>
              <Grid container item md={6} lg={6} xs={6} justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isSomeMainStatusEmpty}
                  onClick={() => sendReportInformation('Confirmado')}>
                  CONFIRMAR
                </Button>
              </Grid>
            </>
          )}
          {isDetailsPage && (
            <Grid container item md={12} lg={12} xs={12} justifyContent="center">
              <Button variant="outlined" color="error" onClick={cancelReportCreation}>
                Volver atrás
              </Button>
            </Grid>
          )}
        </Grid>
        {!isDetailsPage && (
          <MenuToggleButton
            setMainStatusModal={setMainStatusModal}
            setObservationsModal={setObservationsModal}
          />
        )}
      </Grid>
      <MainStatusModal
        setMainStatusModal={setMainStatusModal}
        allMainStatus={allMainStatus}
        mainStatusSelected={mainStatusSelected}
        isEditPage={isEditPage}
      />
      <ObservationModal
        setObservationsModal={setObservationsModal}
        allObservations={allObservations}
        allObservationStatus={allObservationStatus}
        isDetailsPage={isDetailsPage}
        reportId={vehicleInformation?.reportId}
        reportVersion={getReportVersion()}
        uploadingImgModal={uploadingImgModal}
        vehicleLicensePlate={vehicleInformation?.vehicleLicensePlate}
      />
      <VehicleNotFoundModal
        setVehicleNotFoundModal={setVehicleNotFoundModal}
        navigate={navigate}
        isEditPage={isEditPage}
        isCreationPage={isCreationPage}
      />
      <NewMileageModal
        setNewMileageModal={setNewMileageModal}
        currentMileage={vehicleInformation?.vehicleMileage}
        updateVehicleNewMileage={updateVehicleNewMileage}
        cancelReportCreation={cancelReportCreation}
      />
      <LoadingModal openModal={uploadingDataModal} message="ESPERANDO RESPUESTA DE SAP" />
    </>
  );
}

export default CreateReport;
