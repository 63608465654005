/* eslint-disable complexity */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import ReportTableItem from './ReportTableItem';

function ReportTable(props) {
  const { rows, licensePlateSearched, userName } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#002854', color: 'white' }}>
            <TableRow>
              <TableCell align="center" sx={{ color: 'white', fontSize: '18px' }}>
                FICHA
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', fontSize: '18px' }}>
                FECHA
              </TableCell>
              <TableCell align="center" sx={{ color: 'white', fontSize: '18px' }}>
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <ReportTableItem
                row={row}
                licensePlateSearched={licensePlateSearched}
                userName={userName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && (
        <Typography
          component="div"
          align="center"
          style={{ marginBottom: '7px', marginTop: '7px' }}>
          No se han encontrado fichas
        </Typography>
      )}
    </>
  );
}

export default ReportTable;
