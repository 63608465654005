const BASE = '[APP]';

const AppTypes = {
  TOGGLE_ERROR_MESSAGE: `${BASE} TOGGLE_ERROR_MESSAGE`,
  TOGGLE_SUCCESS_MESSAGE: `${BASE} TOGGLE_SUCCESS_MESSAGE`,
  GET_STATE_FROM_API: `${BASE} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${BASE} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_REPLACE_FROM_API_SUCCESS: `${BASE} GET_STATE_REPLACE_FROM_API_SUCCESS`,
  LOG_OUT: `${BASE} LOG_OUT`,
  SET_ERROR_MESSAGE: `${BASE} SET_ERROR_MESSAGE`,
  SET_SUCCESS_MESSAGE: `${BASE} SET_SUCCESS_MESSAGE`,
  SET_LOADER: `${BASE} SET_LOADER`,
  TOGGLE_CONFIRMATION_DIALOG: `${BASE} TOGGLE_CONFIRMATION_DIALOG`,
  MERGE_OBJECTS: `${BASE} MERGE_OBJECTS`,
  DELETE_OBJECTS: `${BASE} DELETE_OBJECTS`,
  MERGE_DELETE_OBJECTS: `${BASE} MERGE_DELETE_OBJECTS`,
};

export default AppTypes;
