/* eslint-disable complexity */
import { put, spawn } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import { normalizeObj } from 'utils/helper';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import { updateReportData } from '../create-report/createReport.actions';

const { takeLatest } = require('redux-saga/effects');
const {
  fetchReport,
  fetchReportSuccess,
  fetchReportFail,
  clearReportInformation,
  copyReportSap,
  copyReportSapSuccess,
  copyReportSapFail,
  deleteReportSap,
  deleteReportSapSuccess,
  deleteReportSapFail,
  printReportSap,
  printReportSapSuccess,
  printReportSapFail,
} = require('./searchReport.actions');

function* fetchReportSaga() {
  yield takeLatest(fetchReport, function* fetchReportToApi(action) {
    const { formattedLicensePlate } = action.payload;
    const vehicleReports = yield fetch(
      `api/v1/vehicle_reports/report-info/${formattedLicensePlate}`,
      {
        method: 'get',
        mode: 'no-cors',
      },
    );
    const response = yield vehicleReports.json();
    if (!response.error) {
      yield defaultSuccessToast({ message: '¡Fichas encontradas con éxito!' });
      yield put(fetchReportSuccess(response));
    } else {
      yield defaultErrorToast({ message: response.error.msg });
      yield put(clearReportInformation());
      yield put(fetchReportFail());
    }
  });
}

function* copyReportSaga() {
  yield takeLatest(copyReportSap, function* fetchReportToApi(action) {
    const body = JSON.stringify(action.payload.reportInformation);
    const copiedReportInformation = yield apiRequest('api/v1/vehicle_reports/copy_report', {
      method: 'post',
      body,
    });
    if (!copiedReportInformation.error) {
      yield defaultSuccessToast({ message: '¡Ficha copiada de forma exitosa!' });
      yield put(
        updateReportData({
          commentary:
            copiedReportInformation.objects.sapReportInformation.vehicleInformation.comments,
        }),
      );
      yield put(copyReportSapSuccess(copiedReportInformation));
      action.payload.navigate('/report/edit');
    } else {
      yield defaultErrorToast({ message: 'Hubo un error generando la copia' });
      yield put(copyReportSapFail());
    }
  });
}

function* deleteReportSaga() {
  yield takeLatest(deleteReportSap, function* deleteReportToApi(action) {
    const { reportId, reportVersion, vehicleLicensePlate } = action.payload.report;
    const { userName } = action.payload;
    let body = '';
    const reportInformation = {
      reportId,
      reportVersion,
      userName,
    };
    body = JSON.stringify(reportInformation);
    const deletedReportResponse = yield apiRequest('api/v1/vehicle_reports/delete_report', {
      method: 'delete',
      body,
    });
    if (!deletedReportResponse.error) {
      body = JSON.stringify({
        reportId,
        reportVersion,
        vehicleLicensePlate,
      });
      const deleteImagesResponse = yield apiRequest('api/v1/vehicle_report_images', {
        method: 'delete',
        body,
      });
      if (!deleteImagesResponse.error) {
        yield put(
          deleteReportSapSuccess({
            ids: {
              vehicleReports: [action.payload.report.id],
            },
            objects: {
              vehicleReports: normalizeObj([action.payload.report]),
            },
          }),
        );
        yield defaultSuccessToast({
          message: '¡Se eliminó la copia de la ficha de forma exitosa!',
        });
      } else {
        yield put(deleteReportSapFail());
        yield defaultErrorToast({
          message: 'Hubo un problema al eliminar la ficha copiada en SAP',
        });
      }
    } else {
      yield put(deleteReportSapFail());
      yield defaultErrorToast({ message: 'Hubo un problema al eliminar la ficha copiada en SAP' });
    }
  });
}

function* printReportSaga() {
  yield takeLatest(printReportSap, function* printReportToApi(action) {
    const body = JSON.stringify(action.payload);
    const printReportResponse = yield apiRequest('api/v1/vehicle_reports/print_report', {
      method: 'post',
      body,
    });
    if (!printReportResponse.error) {
      yield defaultSuccessToast({ message: printReportResponse.sapResponse.msg });
      yield printReportSapSuccess();
    } else {
      yield defaultErrorToast({ message: printReportResponse.error.msg });
      yield printReportSapFail();
    }
  });
}

export default function* SearchReportSaga() {
  yield spawn(fetchReportSaga);
  yield spawn(copyReportSaga);
  yield spawn(deleteReportSaga);
  yield spawn(printReportSaga);
}
