/* Material UI */
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QuizIcon from '@mui/icons-material/Quiz';
import FeedIcon from '@mui/icons-material/Feed';
import SearchIcon from '@mui/icons-material/Search';

/* Router */
import Counter from 'content/pages/Counter/Counter';
import Login from 'content/pages/Login/Login';
import CreateReport from 'content/pages/CreateReport/CreateReport';
import SearchReport from 'content/pages/SearchReport/SearchReport';
import RecoveryPassword from 'content/pages/Recovery/RecoveryPassword';

const Paths = {
  publics: [
    {
      path: '/login',
      component: Login,
      useLayout: false,
      Icon: AccountCircleIcon,
      isAuth: true,
    },
    {
      path: '/recovery/*',
      component: RecoveryPassword,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
  ],
  shared: [],
  protecteds: [
    {
      path: '/',
      name: 'Crear ficha',
      component: SearchReport,
      useLayout: true,
      Icon: FeedIcon,
    },
    {
      path: '/search',
      name: 'Buscar ficha',
      component: SearchReport,
      Icon: SearchIcon,
    },
    {
      path: 'report/create',
      component: CreateReport,
    },
    {
      path: 'report/edit',
      component: CreateReport,
    },
    {
      path: 'report/:reportId/:reportVersion/details',
      component: CreateReport,
    },
  ],
  independients: [
    {
      path: '/faq',
      name: 'FAQ',
      component: Counter,
      Icon: QuizIcon,
    },
  ],
};

export default Paths;
