/* eslint-disable complexity */
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import { useDispatch } from 'react-redux';
import { updateObservationModalData } from '../createReport.actions';

function MenuToggleButton(props) {
  const { setMainStatusModal, setObservationsModal } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openMainStatusModal = () => {
    setMainStatusModal(true);
  };

  const openObservationModal = () => {
    dispatch(
      updateObservationModalData({
        id: null,
        observationType: null,
        observation: null,
        status: null,
        files: [],
      }),
    );
    setObservationsModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      action: 'observations-status',
      render: (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={openObservationModal}
          sx={{
            backgroundColor: 'white',
            color: 'primary',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}>
          <ChatIcon />
        </IconButton>
      ),
    },
    {
      action: 'main-status',
      render: (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={openMainStatusModal}
          sx={{
            backgroundColor: 'white',
            color: 'primary',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}>
          <AssignmentIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div style={{ position: 'fixed', bottom: '50px', right: '60px' }}>
      <IconButton
        aria-label="more"
        id="long-button"
        size="large"
        sx={{
          backgroundColor: '#506CFF',
          color: 'white',
          boxShadow: 3,
          '&:hover': {
            backgroundColor: '#4056CA',
          },
        }}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <AddIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '45px',
            maxHeight: '100%',
            /* overflow: 'auto', */
            border: 'none',
            background: 'none',
            backgroundColor: 'transparent',
            borderRadius: '60px',
            paddingLeft: '5px',
          },
          elevation: 0,
        }}>
        {options.map((option, index) => (
          <MenuItem
            id={option.action}
            key={option.action}
            onClick={handleClose}
            sx={{
              width: '0px',
              borderRadius: '80px',
              padding: '0',
              background: 'none',
              backgroundColor: 'transparent',
              border: '0',
              marginBottom: `${70 * index + 20}px`,
              boxShadow: 3,
            }}>
            {option.render}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default MenuToggleButton;
