/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterStatusByTypeId } from 'utils/functions';
import { LoadingButton } from '@mui/lab';
/* import HighlightOffIcon from '@mui/icons-material/HighlightOff'; */
import ClearIcon from '@mui/icons-material/Clear';
import LoadingModal from 'content/shared/LoadingModal/LoadingModal';
import { normalizeObj } from 'utils/helper';
import CaptureImage from './CaptureImage';
import { observationsModalSelector, reportDataSelector } from '../createReport.selector';
import {
  addObservation,
  updateObservationModalData,
  updateReportData,
  uploadObservationImg,
} from '../createReport.actions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function ObservationModal(props) {
  const {
    setObservationsModal,
    allObservations,
    allObservationStatus,
    isDetailsPage,
    reportId,
    reportVersion,
    uploadingImgModal,
    vehicleLicensePlate,
  } = props;
  const openObservationModal = useSelector(observationsModalSelector);
  const reportData = useSelector(reportDataSelector);

  const dispatch = useDispatch();

  const typeSelectOptions = [
    { description: 'Pintura', type_id: 'P' },
    { description: 'Tapiz', type_id: 'T' },
    { description: 'Neumáticos', type_id: 'N' },
    { description: 'Mecánica', type_id: 'M' },
    { description: 'Estructura', type_id: 'E' },
    { description: 'Parabrisas', type_id: 'B' },
    { description: 'Accesorios', type_id: 'A' },
    { description: 'Documentación', type_id: 'D' },
  ];

  const ObservationTypeSelectedToEdit = typeSelectOptions.filter(
    (item) => item.type_id === reportData?.observationModalInfo?.observation?.type_id,
  );

  const [observationType, setObservationType] = useState('');
  const [observationSelected, setObservationSelected] = useState('');
  const [observationStatusSelected, setObservationStatusSelected] = useState('');
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [capturedImgsUploaded, setCapturedImgsUploaded] = useState([]);
  const [filesRemoved, setFilesRemoved] = useState([]);
  const [openCamera, setOpenCamera] = useState(false);

  const filteredObservations = reportData.observationModalInfo.observationType
    ? filterStatusByTypeId(
        typeSelectOptions.filter(
          (item) => item.description === reportData.observationModalInfo.observationType,
        )[0].type_id,
        allObservations,
      )
    : [];

  const filteredObservationStatus = reportData.observationModalInfo.observation
    ? allObservationStatus.filter(
        (item) => item.group_id === reportData.observationModalInfo.observation.group_id,
      )
    : [];

  const handleObservationTypeChange = (event) => {
    dispatch(
      updateReportData({
        observationModalInfo: {
          id: null,
          observationType: event.target.value,
          observation: null,
          files: [],
        },
      }),
    );
    setObservationType(event.target.value);
    setObservationSelected('');
    setObservationStatusSelected('');
  };

  const handleObservationChange = (event) => {
    dispatch(
      updateObservationModalData({
        id: event.target.value.id,
        observation: event.target.value,
      }),
    );
    setObservationSelected(event.target.value);
    setObservationStatusSelected('');
  };

  const handleObservationStatusChange = (event) => {
    dispatch(
      updateObservationModalData({
        status: event.target.value,
      }),
    );
    setObservationStatusSelected(event.target.value);
  };

  const updateFilesUploaded = (imgUploaded, event) => {};

  const handleFileUpload = (event) => {
    const imgUploaded = [];
    Object.values(event.target.files).forEach((item, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const objToPush = {
          fileObj: event.target.files[index],
          file: {
            name: item.name,
            size: item.size,
            type: item.type,
          },
          url: URL.createObjectURL(item),
          base64String: reader.result,
        };
        imgUploaded.push(objToPush);
        dispatch(
          updateObservationModalData({
            files: reportData.observationModalInfo.files.concat(imgUploaded),
          }),
        );
        setFilesUploaded(filesUploaded.concat(imgUploaded));
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(item);
    });
  };

  const closeObservationModal = () => {
    setObservationsModal(false);
    setObservationType('');
    setObservationSelected('');
    setObservationStatusSelected('');
    setFilesUploaded([]);
    setFilesRemoved([]);
  };

  const handleAddObservation = () => {
    const observationToAdd = {
      objects: {
        observationsSelected: normalizeObj([
          {
            id: reportData.observationModalInfo.observation.id,
            observationType: reportData.observationModalInfo.observationType,
            observation: reportData.observationModalInfo.observation,
            status: reportData.observationModalInfo.status,
            files: reportData.observationModalInfo.files,
          },
        ]),
      },
    };
    if (filesUploaded.length || filesRemoved.length) {
      const fileData = new FormData();
      const images = filesUploaded.map((file) => file.fileObj);
      fileData.append('reportId', reportId);
      fileData.append('reportVersion', reportVersion);
      fileData.append('observationId', reportData.observationModalInfo.observation.id);
      fileData.append('vehicleLicensePlate', vehicleLicensePlate);
      if (filesUploaded.length) {
        const imagesUploaded = filesUploaded.map((file) => file.fileObj);
        imagesUploaded.forEach((img) => {
          fileData.append('files[]', img);
        });
      }
      if (filesRemoved.length) {
        const imagesRemoved = filesRemoved.map((f) => f.file.name);
        imagesRemoved.forEach((img) => {
          fileData.append('imagesRemoved', img);
        });
      }
      dispatch(
        uploadObservationImg({
          fileData,
          closeObservationModal,
          observationToAdd,
        }),
      );
    } else {
      dispatch(addObservation(observationToAdd));
      closeObservationModal();
    }
  };

  const removeFileUploaded = (fileToRemove) => {
    const files = [...reportData.observationModalInfo.files];
    const fileRemoved = files.splice(
      files.findIndex((object) => object === fileToRemove),
      1,
    );
    setFilesRemoved(filesRemoved.concat(fileRemoved));
    setFilesUploaded(files);
    dispatch(
      updateObservationModalData({
        files,
      }),
    );
  };

  const resetUploadInput = () => {
    document.getElementById('imgUpload').value = '';
  };

  const handleOpenCamera = (boolean) => {
    setOpenCamera(boolean);
  };

  return (
    <>
      <Dialog
        open={openObservationModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeObservationModal()}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <Typography sx={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '18px' }}>
            Ingrese observación
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    reportData.observationModalInfo.observationType
                      ? reportData.observationModalInfo.observationType
                      : ''
                  }
                  disabled={isDetailsPage}
                  defaultValue=""
                  label="Tipo"
                  onChange={handleObservationTypeChange}>
                  {typeSelectOptions.map((status) => (
                    <MenuItem key={status.description} value={status.description}>
                      {status.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Observación</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!reportData.observationModalInfo.observationType || isDetailsPage}
                  value={
                    reportData.observationModalInfo.observation
                      ? reportData.observationModalInfo.observation
                      : ''
                  }
                  label="Observación"
                  onChange={handleObservationChange}>
                  {filteredObservations.map((status) => (
                    <MenuItem key={status.description} value={status}>
                      {status.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!reportData.observationModalInfo.observation || isDetailsPage}
                  value={
                    reportData.observationModalInfo.status
                      ? reportData.observationModalInfo.status
                      : ''
                  }
                  label="Estado"
                  onChange={handleObservationStatusChange}>
                  {filteredObservationStatus.map((status) => (
                    <MenuItem key={status.description} value={status}>
                      {status.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isDetailsPage && (
              <Grid container item sm={12} md={12} lg={12} spacing={1}>
                <Grid item>
                  <Button
                    disabled={
                      !(
                        reportData.observationModalInfo.observationType &&
                        reportData.observationModalInfo.observation &&
                        reportData.observationModalInfo.status
                      )
                    }
                    variant="outlined"
                    color="secondary"
                    component="label"
                    onClick={() => handleOpenCamera(true)}
                    endIcon={<CameraAltIcon />}>
                    CAPTURAR IMAGEN
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={
                      !(
                        reportData.observationModalInfo.observationType &&
                        reportData.observationModalInfo.observation &&
                        reportData.observationModalInfo.status
                      )
                    }
                    onClick={resetUploadInput}
                    variant="outlined"
                    color="secondary"
                    component="label"
                    endIcon={<CloudUploadIcon />}>
                    CARGAR IMAGEN
                    <input
                      id="imgUpload"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item sm={12} md={12} lg={12} sx={{ overflow: 'auto' }}>
              <Box component="div" sx={{ overflow: 'auto', display: 'flex' }}>
                {reportData.observationModalInfo.files
                  ? reportData.observationModalInfo.files.map((item) => (
                      <Box
                        component="div"
                        sx={{
                          margin: '0px 10px',
                          border: '1px solid lightgrey',
                          position: 'relative',
                        }}>
                        <img style={{ height: '140px' }} src={item.url} alt="" />
                        {!isDetailsPage && (
                          <IconButton
                            onClick={() => removeFileUploaded(item)}
                            sx={{
                              backgroundColor: 'white',
                              position: 'absolute',
                              margin: '3px',
                              top: '0',
                              right: '0',
                              '&:hover': {
                                backgroundColor: 'darkgrey',
                              },
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Box>
                    ))
                  : ''}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={uploadingImgModal}
            onClick={closeObservationModal}
            variant="outlined"
            color="error">
            {isDetailsPage ? 'Cerrar' : 'Cancelar'}
          </LoadingButton>
          {!isDetailsPage && (
            <LoadingButton
              onClick={handleAddObservation}
              disabled={
                !(
                  reportData.observationModalInfo.observationType &&
                  reportData.observationModalInfo.observation &&
                  reportData.observationModalInfo.status
                )
              }
              loading={uploadingImgModal}
              variant="contained"
              color="secondary">
              Guardar
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      {openCamera && (
        <CaptureImage
          openModal={openCamera}
          Transition={Transition}
          handleCameraModal={handleOpenCamera}
          handleFileUpload={handleFileUpload}
        />
      )}
    </>
  );
}

export default ObservationModal;
