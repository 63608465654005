/* eslint-disable complexity */
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  clearPreLoadedInformation,
  copyReportSap,
  deleteReportSap,
  printReportSap,
  updateActions,
  updateReportSearchData,
} from '../searchReport.actions';
/* import { normalizeObj } from 'utils/helper'; */

function ReportTableActions(props) {
  const { item, licensePlateSearched, userName } = props;
  const options = ['Ficha', 'PDF', 'Copiar', 'Imprimir', 'Eliminar'];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (event) => {
    switch (event.target.id) {
      case 'Ficha':
        dispatch(clearPreLoadedInformation());
        navigate(`/report/${item.reportId}/${item.reportVersion}/details`);
        setAnchorEl(null);
        break;
      case 'Copiar':
        dispatch(clearPreLoadedInformation());
        await dispatch(
          copyReportSap({
            reportInformation: {
              reportId: item.reportId,
              reportVersion: item.reportVersion,
              userName,
            },
            navigate,
          }),
        );
        setAnchorEl(null);
        break;
      case 'Eliminar':
        dispatch(
          deleteReportSap({
            report: {
              ...item,
              vehicleLicensePlate: licensePlateSearched,
            },
            userName,
          }),
        );
        setAnchorEl(null);
        break;
      case 'Imprimir':
        dispatch(
          printReportSap({
            reportId: item.reportId,
            reportVersion: item.reportVersion,
            reportLicensePlate: licensePlateSearched,
          }),
        );
        setAnchorEl(null);
        break;
      case 'PDF':
        if (item?.pdf) {
          dispatch(
            updateReportSearchData({
              currentPdf: item.pdf,
              reportId: item.reportId,
              reportVersion: item.reportVersion,
            }),
          );
          dispatch(updateActions({ pdfViewModal: true }));
        } else {
          defaultErrorToast({ message: 'La ficha no cuenta con un PDF asociado' });
        }
        setAnchorEl(null);
        break;
      default:
        console.log('No pertenece a ninguna opción');
        setAnchorEl(null);
    }
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 52 * 4.5,
            width: '20ch',
          },
        }}>
        {options.map((option) => (
          <MenuItem key={option} onClick={handleClose} id={option}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default ReportTableActions;
