import React from 'react';

/* Material UI */
import { Typography, Grid, TextField, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/* React Redux */
import { useDispatch } from 'react-redux';

/* Project */
import { validateEmail } from 'utils/functions';
import useForm from 'hooks/useForm';

function RecoveryForm(props) {
  const { styles, onChangeText, controls, navigate, email, loading, sendRecoverPasswordEmail } =
    props;

  const dispatch = useDispatch();

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      email: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: (value) => validateEmail(value).check,
          message: 'Correo electrónico inválido',
        },
      ],
    },
  });

  const onClickVerifyEmail = (event) => {
    event.preventDefault();
    const formattedEmail = email.toLowerCase();
    onSubmit(() => dispatch(sendRecoverPasswordEmail({ email: formattedEmail, navigate })));
  };

  return (
    <form onSubmit={onClickVerifyEmail}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={10} textAlign="center">
          <Typography variant="body1" component="div">
            <strong>Restablecer contraseña</strong>
          </Typography>
        </Grid>
        <Grid item xs={10} textAlign="justify">
          <Typography variant="body1" component="div">
            Ingresa tu correo electrónico y recibirás instrucciones para una nueva contraseña.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <TextField
            sx={styles.field}
            label="Correo electrónico"
            key="email"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            style={{ paddingBottom: '8px' }}
            fullWidth
            error={!!errors.email[0]}
            helperText={errors.email[0]}
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justifyContent="space-between"
          alignItems="center"
          sx={styles.textAlign}>
          <IconButton edge="start" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '5px' }}
            sx={styles.button}>
            Enviar
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default RecoveryForm;
