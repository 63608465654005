/* eslint-disable complexity */
/* eslint-disable max-depth */
import { takeLatest, spawn, put } from 'redux-saga/effects';
import apiRequest, { postForm } from 'utils/api';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  fetchMainStatusSuccess,
  fetchMainStatusFail,
  fetchMainStatus,
  fetchObservations,
  fetchObservationsSuccess,
  fetchObservationsFail,
  fetchObservationStatus,
  fetchObservationStatusSuccess,
  fetchObservationStatusFail,
  fetchVehiclePrice,
  fetchVehiclePriceSuccess,
  fetchVehiclePriceFail,
  deleteReportSap,
  deleteReportSapFail,
  deleteReportSapSuccess,
  fetchReportDetails,
  fetchReportDetailsSuccess,
  fetchReportDetailsFail,
  uploadReportInformation,
  uploadReportInformationFail,
  fetchMaxReportNumber,
  fetchMaxReportNumberSuccess,
  fetchMaxReportNumberFail,
  updateVehicleInformationCreateReport,
  uploadObservationImg,
  uploadObservationImgFail,
  uploadObservationImgSuccess,
  addObservation,
  updateReportData,
  deleteReportImagesSuccess,
  deleteReportImages,
  uploadReportInformationSuccess,
} from './createReport.actions';
import { clearReportInformation } from '../search-report/searchReport.actions';

function* fetchAllMainStatus() {
  yield takeLatest(fetchMainStatus, function* fetchMainStatusToApi() {
    const response = yield apiRequest('api/v1/main_status', { method: 'GET' });
    if (response) {
      yield put(fetchMainStatusSuccess({ objects: response.objects }));
    } else {
      yield put(fetchMainStatusFail());
    }
  });
}

function* fetchAllObservations() {
  yield takeLatest(fetchObservations, function* fetchObservationsApi() {
    const response = yield apiRequest('api/v1/observations/', { method: 'GET' });
    if (response) {
      yield put(fetchObservationsSuccess({ objects: response.objects }));
    } else {
      yield put(fetchObservationsFail());
    }
  });
}

function* fetchAllObservationStatus() {
  yield takeLatest(fetchObservationStatus, function* fetchObservationStatusToApi() {
    const response = yield apiRequest('api/v1/observation_status/', { method: 'GET' });
    if (response) {
      yield put(fetchObservationStatusSuccess({ objects: response.objects }));
    } else {
      yield put(fetchObservationStatusFail());
    }
  });
}

function* fetchVehiclePriceSaga() {
  yield takeLatest(fetchVehiclePrice, function* fetchVehiclePriceToApi(action) {
    const body = JSON.stringify(action.payload.vehicleInformation);
    const { vehicleVBaja, toggleNewMileageModal } = action.payload;
    const response = yield apiRequest('api/v1/vehicle_prices/', { method: 'POST', body });
    if (!response.error) {
      yield put(
        fetchVehiclePriceSuccess({
          objects: response.vehiclePrice,
          toggleModal: toggleNewMileageModal,
        }),
      );
    } else {
      if (Number(vehicleVBaja) === 0)
        yield defaultErrorToast({ message: 'Precio del vehículo no encontrado.' });
      yield put(
        fetchVehiclePriceFail({
          toggleModal: toggleNewMileageModal,
        }),
      );
    }
  });
}

function* deleteReportSaga() {
  yield takeLatest(deleteReportSap, function* deleteReportToApi(action) {
    let body = '';
    const reportInformation = {
      reportId: action.payload.report.reportId,
      reportVersion: action.payload.report.reportVersion,
      userName: action.payload.userName,
    };
    body = JSON.stringify(reportInformation);
    const deletedReportResponse = yield apiRequest('api/v1/vehicle_reports/delete_report', {
      method: 'delete',
      body,
    });
    if (!deletedReportResponse.error) {
      body = JSON.stringify({
        reportId: action.payload.report.reportId,
        reportVersion: action.payload.report.reportVersion,
        vehicleLicensePlate: action.payload.report.vehicleLicensePlate,
      });
      const deleteImagesResponse = yield apiRequest('api/v1/vehicle_report_images', {
        method: 'delete',
        body,
      });
      if (!deleteImagesResponse.error) {
        yield defaultSuccessToast({
          message: '¡Se eliminó la copia de la ficha de forma exitosa!',
        });
        yield put(deleteReportSapSuccess());
      } else {
        yield defaultErrorToast({
          message: 'Hubo un problema al eliminar la ficha copiada en SAP',
        });
        yield put(deleteReportSapFail());
      }
    } else {
      yield defaultErrorToast({ message: 'Hubo un problema al eliminar la ficha copiada en SAP' });
      yield put(deleteReportSapFail());
    }
  });
}

function* fetchReportDetailsSaga() {
  yield takeLatest(fetchReportDetails, function* fetchReportDetailsToApi(action) {
    const reportInformation = {
      reportId: action.payload.reportId,
      reportVersion: action.payload.reportVersion,
      userName: action.payload.userName,
    };
    const body = JSON.stringify(reportInformation);
    const reportDetailResponse = yield apiRequest('api/v1/vehicle_reports/report_details', {
      method: 'post',
      body,
    });
    if (!reportDetailResponse.error) {
      yield put(
        updateReportData({
          commentary: reportDetailResponse.objects.sapReportInformation.vehicleInformation.comments,
        }),
      );
      yield put(fetchReportDetailsSuccess(reportDetailResponse));
      yield put(
        fetchVehiclePrice({
          vehicleInformation: {
            vehicleYear:
              reportDetailResponse.objects.sapReportInformation.vehicleInformation.vehicleYear,
            vehicleModel:
              reportDetailResponse.objects.sapReportInformation.vehicleInformation.vehicleModel,
            vehicleMileage:
              reportDetailResponse.objects.sapReportInformation.vehicleInformation.vehicleMileage,
            licensePlate:
              reportDetailResponse.objects.sapReportInformation.vehicleInformation
                .vehicleLicensePlate,
          },
          toggleNewMileageModal: false,
        }),
      );
    } else {
      yield defaultErrorToast({ message: 'Hubo un error obteniendo la información de la ficha' });
      yield put(fetchReportDetailsFail());
    }
  });
}

function* fetchMaxReportNumberSaga() {
  yield takeLatest(fetchMaxReportNumber, function* fetchMaxReportNumberToApi() {
    const response = yield apiRequest('api/v1/vehicle_reports/next_report_number', {
      method: 'GET',
    });
    if (response) {
      yield put(fetchMaxReportNumberSuccess());
      yield put(
        updateVehicleInformationCreateReport({
          reportId: response.maxReportNumber + 1,
          reportVersion: 1,
        }),
      );
    } else {
      yield defaultErrorToast({
        message: 'Hubo un error obteniendo el nuevo número de ficha a crear',
      });
      yield put(fetchMaxReportNumberFail());
    }
  });
}

function* uploadObservationImageSaga() {
  yield takeLatest(uploadObservationImg, function* uploadObservationImageToApi(action) {
    const body = action.payload.fileData;
    const response = yield postForm('api/v1/vehicle_reports/upload_image', body);
    if (!response.error) {
      yield put(uploadObservationImgSuccess());
      yield put(addObservation(action.payload.observationToAdd));
      yield defaultSuccessToast({ message: '¡Imágenes cargadas con éxito!' });
      action.payload.closeObservationModal();
    } else {
      yield put(uploadObservationImgFail());
      yield defaultErrorToast({ message: 'Hubo un error cargando las imágenes' });
    }
  });
}

function* deleteReportImagesSaga() {
  yield takeLatest(deleteReportImages, function* deleteReportImageToApi(action) {
    const body = JSON.stringify({
      reportId: action.payload.reportId,
      reportVersion: action.payload.reportVersion,
      vehicleLicensePlate: action.payload.vehicleLicensePlate,
    });
    yield apiRequest('api/v1/vehicle_report_images', {
      method: 'delete',
      body,
    });
    yield put(deleteReportImagesSuccess());
  });
}

function* uploadReportInformationSaga() {
  yield takeLatest(uploadReportInformation, function* uploadReportInformationToApi(action) {
    const socketId = localStorage.getItem('socketId');
    const reportToStore = {
      reportNumber: action.payload.vehicleInformation.reportId,
      reportVersion: action.payload.vehicleInformation.reportNewVersion
        ? action.payload.vehicleInformation.reportNewVersion
        : action.payload.vehicleInformation.reportVersion,
      vehicleLicensePlate: action.payload.vehicleInformation.vehicleLicensePlate,
      vehicleBrand: action.payload.vehicleInformation.vehicleBrand,
      vehicleMileage: Number(action.payload.vehicleInformation.vehicleNewMileage),
      vehicleModel: action.payload.vehicleInformation.vehicleModel,
      vehicleYear: Number(action.payload.vehicleInformation.vehicleYear),
      date: new Date(),
      cCost: action.payload.vehicleInformation.vehicleCcost,
      saveStatus: action.payload.saveStatus,
      finalPrice:
        Number(
          action.payload.vehicleInformation.vehicleBasePrice ||
            action.payload.vehicleInformation.vehicleVBaja ||
            0,
        ) - Number(action.payload.vehicleInformation.vehicleTotalDiscounts),
      comments: action.payload.vehicleInformation.comments,
      vBaja: action.payload.vehicleInformation.vehicleVBaja,
    };
    const mainStatusToStore = action.payload.mainStatusSelected.map((mainStatus) => ({
      mainStatusId: mainStatus.id,
      result: mainStatus.description,
      value: mainStatus.value,
    }));
    const observationsToStore = action.payload.observationsSelected.map((observation) => ({
      observationStatusId: observation.status.id,
      vehicleReportNumber: action.payload.vehicleInformation.reportId,
      vehicleReportVersion: action.payload.vehicleInformation.reportNewVersion
        ? action.payload.vehicleInformation.reportNewVersion
        : action.payload.vehicleInformation.reportVersion,
      vehicleLicensePlate: action.payload.vehicleInformation.vehicleLicensePlate,
      observationId: observation.id,
      value: observation.status.value,
      files: observation.files ? Object.values(observation.files).map((f) => f.file.name) : [],
    }));
    const reportTotalInformation = {
      reportToStore,
      mainStatusToStore,
      observationsToStore,
      socketId,
      userId: action.payload.userId,
    };
    const body = JSON.stringify(reportTotalInformation);
    const uploadReportResponse = yield apiRequest('api/v1/vehicle_reports/create_report', {
      method: 'post',
      body,
    });
    if (!uploadReportResponse?.error) {
      yield put(
        uploadReportInformationSuccess({
          reportId: Number(uploadReportResponse.reportInformation.reportNumber),
          reportVersion: Number(uploadReportResponse.reportInformation.reportVersion),
          reportNewVersion: Number(uploadReportResponse.reportInformation.reportVersion) + 1,
        }),
      );
      yield put(clearReportInformation());
      action.payload.navigate('/');
      yield defaultSuccessToast({
        message: `Ficha del vehículo ${action.payload.vehicleInformation.vehicleLicensePlate} está siendo guardada en el sistema...`,
      });
    } else {
      yield put(uploadReportInformationFail());
      yield defaultErrorToast({
        message: 'Hubo un error guardando la información de la ficha',
      });
    }
  });
}

export default function* CreateReportSaga() {
  yield spawn(fetchAllMainStatus);
  yield spawn(fetchAllObservations);
  yield spawn(fetchAllObservationStatus);
  yield spawn(fetchVehiclePriceSaga);
  yield spawn(deleteReportSaga);
  yield spawn(fetchReportDetailsSaga);
  yield spawn(uploadReportInformationSaga);
  yield spawn(fetchMaxReportNumberSaga);
  yield spawn(uploadObservationImageSaga);
  yield spawn(deleteReportImagesSaga);
}
