import { createReducer } from '@reduxjs/toolkit';

/* Project */
import {
  loginSuccess,
  googleLoginSuccess,
  logoutSuccess,
} from 'content/features/authentication/login/login.actions';
import { removeManyKeysByFilter } from 'utils/functions';
import { normalizeObj } from 'utils/helper';
import {
  getStateFromApiSuccess,
  getStateReplaceFromApiSuccess,
  toggleErrorMessage,
  setErrorMessage,
  toggleSuccessMessage,
  toggleConfirmationDialog,
  setSuccessMessage,
  mergeObjectsReducer,
  deleteObjectsReducer,
  mergeDeleteObjectsReducer,
} from './app.actions';
import {
  checkTaskSuccess,
  createTaskSuccess,
  fetchTodoListSuccess,
  removeTaskSuccess,
} from './features/tasks/userTasks.actions';
import {
  addMainStatus,
  addObservation,
  fetchMainStatusSuccess,
  fetchObservationStatusSuccess,
  fetchObservationsSuccess,
  fetchReportDetails,
  fetchReportDetailsSuccess,
  fetchVehiclePriceSuccess,
  removeObservation,
  updateVehicleInformationCreateReport,
  uploadReportInformationSuccess,
} from './features/create-report/createReport.actions';
import {
  clearVehicleInformation,
  fetchVehicleInformationSuccess,
  updateVehicleInformation,
} from './features/search-vehicle/searchVehicle.actions';
import {
  clearPreLoadedInformation,
  clearReportInformation,
  copyReportSapSuccess,
  deleteReportSapSuccess,
  fetchReportSuccess,
} from './features/search-report/searchReport.actions';

const initialState = {
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  signedByGoogle: false,
  confirmDialog: {
    open: false,
    message: '',
    severity: 'info',
    callback: null,
  },
  objects: {
    federatedClients: {},
    tasks: {},
    users: {},
    mainStatus: {},
    mainStatusSelected: {
      paintStatus: '',
      tapestryStatus: '',
      tiresStatus: '',
      mechanicsStatus: '',
      structureStatus: '',
      windshieldStatus: '',
    },
    observations: {},
    observationStatus: {},
    observationsSelected: {},
    vehicleInformation: {},
    vehicleReports: {},
    preLoadedInformation: {},
  },
  user: null,
  loading: true,
};

const mergeObjects = (state, action) => {
  if (!action.payload.objects) return state.objects;

  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects?.[key] || {}),
    };
  });

  return newObjects;
};

const replaceObjects = (state, action) => {
  if (!action.payload.objects) return state.objects;

  const newObjects = { ...state.objects };

  Object.keys(action.payload.objects).forEach((key) => {
    newObjects[key] = {
      ...(action.payload.objects[key] || {}),
    };
  });

  return newObjects;
};

const deleteObjects = (state, action) => {
  // if (!action.payload.objects) return state.objects;

  const newObjects = { ...state.objects };

  Object.keys(action.payload.ids).forEach((key) => {
    newObjects[key] = removeManyKeysByFilter(newObjects[key], action.payload.ids[key]);
  });

  return newObjects;
};

// eslint-disable-next-line
const mergeDeleteObjects = (state, action) => {
  const newObjects = {};

  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });

  Object.keys(action.payload.ids).forEach((key) => {
    newObjects[key] = removeManyKeysByFilter(newObjects[key], action.payload.ids[key]);
  });

  return newObjects;
};

const AppReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getStateFromApiSuccess, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.signedByGoogle = action.payload.signedByGoogle;
      state.objects = mergeObjects(state, action);
    })
    .addCase(getStateReplaceFromApiSuccess, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.signedByGoogle = action.payload.signedByGoogle;
      state.objects = replaceObjects(state, action);
    })
    .addCase(toggleErrorMessage, (state, action) => {
      state.error = action.payload.error;
      state.errorMsg = action.payload.msg || '';
    })
    .addCase(setErrorMessage, (state, action) => {
      state.error = true;
      state.errorMsg = action.payload.msg;
    })
    .addCase(toggleSuccessMessage, (state, action) => {
      state.success = action.payload.success;
      state.successMsg = action.payload.msg || '';
    })
    .addCase(setSuccessMessage, (state, action) => {
      state.success = true;
      state.successMsg = action.payload.msg;
    })
    .addCase(toggleConfirmationDialog, (state, action) => {
      state.confirmDialog = {
        ...action.payload,
      };
    })
    .addCase(loginSuccess, (state, action) => {
      state.loadingUser = false;
      state.user = action.payload.user;
      state.signedByGoogle = false;
      state.objects = mergeObjects(state, action);
    })
    .addCase(googleLoginSuccess, (state, action) => {
      state.loadingUser = false;
      state.user = action.payload.user;
      state.signedByGoogle = true;
      state.objects = mergeObjects(state, action);
    })
    .addCase(logoutSuccess, (state) => {
      state.user = null;
      state.signedByGoogle = false;
    })
    .addCase(fetchTodoListSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(createTaskSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(checkTaskSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(removeTaskSuccess, (state, action) => {
      state.objects = deleteObjects(state, action);
    })
    .addCase(mergeObjectsReducer, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(deleteObjectsReducer, (state, action) => {
      state.objects = deleteObjects(state, action);
    })
    .addCase(mergeDeleteObjectsReducer, (state, action) => {
      state.objects = mergeDeleteObjects(state, action);
    })
    .addCase(fetchMainStatusSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(fetchObservationsSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(fetchObservationStatusSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(fetchVehicleInformationSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(fetchReportSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(clearReportInformation, (state) => {
      state.objects.vehicleReports = initialState.objects.vehicleReports;
      state.objects.mainStatusSelected = initialState.objects.mainStatusSelected;
      state.objects.observationsSelected = initialState.objects.observationsSelected;
    })
    .addCase(clearVehicleInformation, (state) => {
      state.objects.vehicleInformation = initialState.objects.vehicleInformation;
      state.objects.preLoadedInformation = initialState.objects.preLoadedInformation;
      state.objects = initialState.objects;
    })
    .addCase(addObservation, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(removeObservation, (state, action) => {
      state.objects = deleteObjects(state, action);
    })
    .addCase(deleteReportSapSuccess, (state, action) => {
      state.objects = deleteObjects(state, action);
    })
    .addCase(updateVehicleInformation, (state, action) => {
      state.objects.vehicleInformation = normalizeObj([
        {
          ...state.objects.vehicleInformation['0'],
          ...action.payload,
        },
      ]);
    })
    .addCase(updateVehicleInformationCreateReport, (state, action) => {
      state.objects.vehicleInformation = normalizeObj([
        {
          ...state.objects.vehicleInformation['0'],
          ...action.payload,
        },
      ]);
    })
    .addCase(copyReportSapSuccess, (state, action) => {
      state.objects.vehicleInformation = normalizeObj([
        {
          ...action.payload.objects.sapReportInformation.vehicleInformation,
        },
      ]);
      state.objects.preLoadedInformation = {
        ...action.payload.objects.sapReportInformation.reportInformation,
      };
    })
    .addCase(fetchReportDetails, (state) => {
      state.objects.vehicleInformation = initialState.objects.vehicleInformation;
      state.objects.preLoadedInformation = initialState.objects.preLoadedInformation;
    })
    .addCase(fetchReportDetailsSuccess, (state, action) => {
      state.objects.vehicleInformation = normalizeObj([
        {
          ...action.payload.objects.sapReportInformation.vehicleInformation,
        },
      ]);
      state.objects.preLoadedInformation = {
        ...action.payload.objects.sapReportInformation.reportInformation,
      };
    })
    .addCase(uploadReportInformationSuccess, (state, action) => {
      console.log(action);
      state.objects.vehicleInformation['0'].reportId = action.payload.reportId;
      state.objects.vehicleInformation['0'].reportVersion = action.payload.reportVersion;
      state.objects.vehicleInformation['0'].reportNewVersion = action.payload.reportNewVersion;
    })
    .addCase(fetchVehiclePriceSuccess, (state, action) => {
      state.objects.vehicleInformation['0'].vehicleBasePrice = action.payload.objects.price;
    })
    .addCase(clearPreLoadedInformation, (state) => {
      state.objects.vehicleInformation = initialState.objects.vehicleInformation;
      state.objects.preLoadedInformation = initialState.objects.preLoadedInformation;
      state.objects.mainStatusSelected = initialState.objects.mainStatusSelected;
      state.objects.observationsSelected = initialState.objects.observationsSelected;
      state.objects.observations = initialState.objects.observations;
      state.objects.mainStatus = initialState.objects.mainStatus;
      state.objects.observationsSelected = initialState.objects.observationsSelected;
      state.objects.observationStatus = initialState.objects.observationStatus;
    })
    .addCase(addMainStatus, (state, action) => {
      state.objects = mergeObjects(state, action);
    });
});

export default AppReducer;
