export const loadersSelector = (state) => state.searchReport.loaders;

export const searchDataSelector = (state) => state.searchReport.searchData;

export const actionsSelector = (state) => state.searchReport.actions;

export const searchingReportsSelector = (state) => state.searchReport.actions.searchingReports;

const SearchReportSelector = {
  loaders: loadersSelector,
  searchData: searchDataSelector,
  actions: actionsSelector,
  searchingReports: searchingReportsSelector,
};

export default SearchReportSelector;
