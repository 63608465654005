import { createAction } from '@reduxjs/toolkit';
import SearchVehicleTypes from './searchVehicle.types';

export const changeActions = createAction(SearchVehicleTypes.CHANGE_ACTIONS);

export const fetchVehicleInformation = createAction(
  SearchVehicleTypes.FETCH_VEHICLE_INFORMATION,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchVehicleInformationSuccess = createAction(
  SearchVehicleTypes.FETCH_VEHICLE_INFORMATION_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchVehicleInformationFail = createAction(
  SearchVehicleTypes.FETCH_VEHICLE_INFORMATION_FAIL,
);

export const clearVehicleInformation = createAction(SearchVehicleTypes.CLEAR_VEHICLE_INFORMATION);

export const updateVehicleInformation = createAction(SearchVehicleTypes.UPDATE_VEHICLE_INFORMATION);

const SearchVehicleActions = {
  changeActions,

  fetchVehicleInformation,
  fetchVehicleInformationSuccess,
  fetchVehicleInformationFail,

  clearVehicleInformation,
  updateVehicleInformation,
};

export default SearchVehicleActions;
