import React from 'react';

// Material
import Grid from '@mui/material/Grid';

/* Project */
// import logo from '/assets/images/TcitLogo_123x50.png';
const TCITFooterLogo = `${window.location.origin}/images/TcitLogo_123x50.png`;

function Footer() {
  return (
    <div className="footer" style={{ minHeight: '7vh' }}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <img src={TCITFooterLogo} alt="logo" className="footer-logo" />
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
