/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainStatusModalSelector } from '../createReport.selector';
import { addMainStatus } from '../createReport.actions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function MainStatusModal(props) {
  const { setMainStatusModal, allMainStatus, mainStatusSelected, isEditPage } = props;
  const openMainStatusModal = useSelector(mainStatusModalSelector);
  const dispatch = useDispatch();

  const filterStatusByTypeId = (typeId, arrayToFilter) => {
    const statusFiltered = arrayToFilter
      ? arrayToFilter.filter((item) => item?.type_id === typeId)
      : [];
    return statusFiltered;
  };

  const filterMainStatusSelected = (typeId, statusArray) => {
    const mainStatus = statusArray.filter((item) => item.type_id === typeId).length
      ? statusArray.filter((item) => item.type_id === typeId)[0]
      : '';

    return mainStatus;
  };

  const [paintStatus, setPaintStatus] = useState(mainStatusSelected[0] || '');
  const [tapestryStatus, setTapestryStatus] = useState(mainStatusSelected[1] || '');
  const [tiresStatus, setTiresStatus] = useState(mainStatusSelected[2] || '');
  const [mechanicsStatus, setMechanicsStatus] = useState(mainStatusSelected[3] || '');
  const [structureStatus, setStructureStatus] = useState(mainStatusSelected[4] || '');
  const [windshieldStatus, setWindshieldStatus] = useState(mainStatusSelected[5] || '');

  // eslint-disable-next-line complexity
  const handleChange = (event) => {
    switch (event.target.value.type_id) {
      case 'P':
        setPaintStatus(event.target.value);
        break;
      case 'E':
        setStructureStatus(event.target.value);
        break;
      case 'M':
        setMechanicsStatus(event.target.value);
        break;
      case 'T':
        setTapestryStatus(event.target.value);
        break;
      case 'B':
        setWindshieldStatus(event.target.value);
        break;
      case 'N':
        setTiresStatus(event.target.value);
        break;
      default:
        console.log('No pertenece a ningún tipo de estado principal');
    }
  };

  const updateStatusStates = () => {
    setPaintStatus(filterMainStatusSelected('P', mainStatusSelected));
    setTapestryStatus(filterMainStatusSelected('T', mainStatusSelected));
    setTiresStatus(filterMainStatusSelected('N', mainStatusSelected));
    setMechanicsStatus(filterMainStatusSelected('M', mainStatusSelected));
    setStructureStatus(filterMainStatusSelected('E', mainStatusSelected));
    setWindshieldStatus(filterMainStatusSelected('B', mainStatusSelected));
  };

  useEffect(() => {
    if (isEditPage) {
      updateStatusStates();
    }
  }, [openMainStatusModal]);

  const closeMainStatusModal = () => {
    setMainStatusModal(false);
    updateStatusStates();
  };

  const confirmMainStatus = () => {
    const mainStatusValues = {
      paintStatus,
      tapestryStatus,
      tiresStatus,
      mechanicsStatus,
      structureStatus,
      windshieldStatus,
    };
    dispatch(addMainStatus({ objects: { mainStatusSelected: mainStatusValues } }));
    setMainStatusModal(false);
  };

  return (
    <Dialog
      open={openMainStatusModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => closeMainStatusModal()}
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grid container spacing={3}>
          <Grid container item sm={12} md={12} lg={12} justifyContent="left">
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Estados</Typography>
          </Grid>
          <Grid container item sm={4} md={4} lg={4} justifyContent="left">
            <Typography sx={{ marginBottom: '7px', fontWeight: 'bold' }}>Pintura</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paintStatus}
                label="Estado"
                onChange={handleChange}>
                {filterStatusByTypeId('P', allMainStatus).map((status) => (
                  <MenuItem key={status.description} value={status}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item sm={4} md={4} lg={4} justifyContent="left">
            <Typography sx={{ marginBottom: '7px', fontWeight: 'bold' }}>Tapiz</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tapestryStatus}
                label="Estado"
                onChange={handleChange}>
                {filterStatusByTypeId('T', allMainStatus).map((status) => (
                  <MenuItem key={status.description} value={status}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item sm={4} md={4} lg={4} justifyContent="left">
            <Typography sx={{ marginBottom: '7px', fontWeight: 'bold' }}>Neumáticos</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tiresStatus}
                label="Estado"
                onChange={handleChange}>
                {filterStatusByTypeId('N', allMainStatus).map((status) => (
                  <MenuItem key={status.description} value={status}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item sm={4} md={4} lg={4} justifyContent="left">
            <Typography sx={{ marginBottom: '7px', fontWeight: 'bold' }}>Mecánica</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mechanicsStatus}
                label="Estado"
                onChange={handleChange}>
                {filterStatusByTypeId('M', allMainStatus).map((status) => (
                  <MenuItem key={status.description} value={status}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item sm={4} md={4} lg={4} justifyContent="left">
            <Typography sx={{ marginBottom: '7px', fontWeight: 'bold' }}>Estructura</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={structureStatus}
                label="Estado"
                onChange={handleChange}>
                {filterStatusByTypeId('E', allMainStatus).map((status) => (
                  <MenuItem key={status.description} value={status}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item sm={4} md={4} lg={4} justifyContent="left">
            <Typography sx={{ marginBottom: '7px', fontWeight: 'bold' }}>Parabrisas</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={windshieldStatus}
                label="Estado"
                onChange={handleChange}>
                {filterStatusByTypeId('B', allMainStatus).map((status) => (
                  <MenuItem key={status.description} value={status}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeMainStatusModal} variant="outlined" color="error">
          Cancelar
        </Button>
        <Button
          onClick={confirmMainStatus}
          disabled={!mainStatusSelected.length}
          variant="contained"
          color="secondary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MainStatusModal;
