import {
  copyReportSap,
  copyReportSapFail,
  copyReportSapSuccess,
  deleteReportSap,
  deleteReportSapFail,
  deleteReportSapSuccess,
  fetchReport,
  fetchReportFail,
  fetchReportSuccess,
  updateActions,
  updateReportSearchData,
} from './searchReport.actions';

const { createReducer } = require('@reduxjs/toolkit');

const initialState = {
  loaders: {
    page: false,
    update: false,
    loadingCopyReport: false,
  },
  actions: {
    newMileageModal: false,
    pdfViewModal: false,
    searchingReports: false,
  },
  searchData: {
    licensePlateSearched: '',
    currentPdf: '',
  },
};

const SearchReportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(copyReportSap, (state) => {
      state.loaders.loadingCopyReport = true;
    })
    .addCase(copyReportSapSuccess, (state) => {
      state.loaders.loadingCopyReport = false;
    })
    .addCase(copyReportSapFail, (state) => {
      state.loaders.loadingCopyReport = false;
    })
    .addCase(deleteReportSap, (state) => {
      state.loaders.loadingCopyReport = true;
    })
    .addCase(deleteReportSapSuccess, (state) => {
      state.loaders.loadingCopyReport = false;
    })
    .addCase(deleteReportSapFail, (state) => {
      state.loaders.loadingCopyReport = false;
    })
    .addCase(updateReportSearchData, (state, action) => {
      state.searchData = {
        ...state.searchData,
        ...action.payload,
      };
    })
    .addCase(updateActions, (state, action) => {
      state.actions = {
        ...state.actions,
        ...action.payload,
      };
    })
    .addCase(fetchReport, (state) => {
      state.actions.searchingReports = true;
    })
    .addCase(fetchReportSuccess, (state) => {
      state.actions.searchingReports = false;
    })
    .addCase(fetchReportFail, (state) => {
      state.actions.searchingReports = false;
    });
});

export default SearchReportReducer;
