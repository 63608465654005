import { createAction } from '@reduxjs/toolkit';
import CreateReportTypes from './createReport.types';

export const changeActions = createAction(CreateReportTypes.CHANGE_ACTIONS);

export const fetchMainStatus = createAction(CreateReportTypes.FETCH_MAIN_STATUS, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const fetchMainStatusSuccess = createAction(
  CreateReportTypes.FETCH_MAIN_STATUS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchMainStatusFail = createAction(CreateReportTypes.FETCH_MAIN_STATUS_FAIL);

export const addMainStatus = createAction(CreateReportTypes.ADD_MAIN_STATUS);

export const fetchObservations = createAction(CreateReportTypes.FETCH_OBSERVATIONS, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const fetchObservationsSuccess = createAction(
  CreateReportTypes.FETCH_OBSERVATIONS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchObservationsFail = createAction(CreateReportTypes.FETCH_OBSERVATIONS_FAIL);

export const fetchObservationStatus = createAction(
  CreateReportTypes.FETCH_OBSERVATION_STATUS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchObservationStatusSuccess = createAction(
  CreateReportTypes.FETCH_OBSERVATION_STATUS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchObservationStatusFail = createAction(
  CreateReportTypes.FETCH_OBSERVATION_STATUS_FAIL,
);

export const addObservation = createAction(CreateReportTypes.ADD_OBSERVATION);

export const removeObservation = createAction(CreateReportTypes.REMOVE_OBSERVATION);

export const updateReportData = createAction(CreateReportTypes.UPDATE_REPORT_DATA);

export const updateObservationModalData = createAction(
  CreateReportTypes.UPDATE_OBSERVATION_MODAL_DATA,
);

export const fetchVehiclePrice = createAction(CreateReportTypes.FETCH_VEHICLE_PRICE, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const fetchVehiclePriceSuccess = createAction(
  CreateReportTypes.FETCH_VEHICLE_PRICE_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchVehiclePriceFail = createAction(
  CreateReportTypes.FETCH_VEHICLE_PRICE_FAIL,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const updateVehicleInformationCreateReport = createAction(
  CreateReportTypes.UPDATE_VEHICLE_INFORMATION,
);

export const deleteReportSap = createAction(CreateReportTypes.DELETE_REPORT, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const deleteReportSapSuccess = createAction(CreateReportTypes.DELETE_REPORT_SUCCESS);

export const deleteReportSapFail = createAction(CreateReportTypes.DELETE_REPORT_FAIL);

export const fetchReportDetails = createAction(
  CreateReportTypes.FETCH_REPORT_DETAILS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchReportDetailsSuccess = createAction(
  CreateReportTypes.FETCH_REPORT_DETAILS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchReportDetailsFail = createAction(CreateReportTypes.FETCH_REPORT_DETAILS_FAIL);

export const uploadReportInformation = createAction(
  CreateReportTypes.UPLOAD_REPORT_INFORMATION,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const uploadReportInformationSuccess = createAction(
  CreateReportTypes.UPLOAD_REPORT_INFORMATION_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const uploadReportInformationFail = createAction(
  CreateReportTypes.UPLOAD_REPORT_INFORMATION_FAIL,
);

export const fetchMaxReportNumber = createAction(CreateReportTypes.FETCH_MAX_REPORT_NUMBER);

export const fetchMaxReportNumberSuccess = createAction(
  CreateReportTypes.FETCH_MAX_REPORT_NUMBER_SUCCESS,
);

export const fetchMaxReportNumberFail = createAction(
  CreateReportTypes.FETCH_MAX_REPORT_NUMBER_FAIL,
);

export const uploadObservationImg = createAction(CreateReportTypes.UPLOAD_OBSERVATION_IMAGE);

export const uploadObservationImgSuccess = createAction(
  CreateReportTypes.UPLOAD_OBSERVATION_IMAGE_SUCCESS,
);

export const uploadObservationImgFail = createAction(
  CreateReportTypes.UPLOAD_OBSERVATION_IMAGE_FAIL,
);

export const deleteReportImages = createAction(
  CreateReportTypes.DELETE_REPORT_IMAGES,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const deleteReportImagesSuccess = createAction(
  CreateReportTypes.DELETE_REPORT_IMAGES_SUCCESS,
);

const CreateReportActions = {
  changeActions,

  fetchMainStatus,
  fetchMainStatusSuccess,
  fetchMainStatusFail,

  addMainStatus,

  fetchObservations,
  fetchObservationsFail,
  fetchObservationsSuccess,

  fetchObservationStatus,
  fetchObservationStatusSuccess,
  fetchObservationStatusFail,

  addObservation,
  removeObservation,

  updateReportData,
  updateObservationModalData,

  fetchVehiclePrice,
  fetchVehiclePriceSuccess,
  fetchVehiclePriceFail,

  fetchReportDetails,
  fetchReportDetailsSuccess,
  fetchReportDetailsFail,

  updateVehicleInformationCreateReport,

  uploadReportInformation,
  uploadReportInformationSuccess,
  uploadReportInformationFail,

  fetchMaxReportNumber,
  fetchMaxReportNumberSuccess,
  fetchMaxReportNumberFail,

  uploadObservationImg,
  uploadObservationImgSuccess,
  uploadObservationImgFail,

  deleteReportImages,
  deleteReportImagesSuccess,
};
export default CreateReportActions;
