/* eslint-disable complexity */
import { Box, Grid } from '@mui/material';
import React from 'react';

function VehicleInformation(props) {
  const { vehicleInformation, isDetailsPage } = props;
  return (
    <Grid container>
      <Grid item md={6} lg={6} xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        Matrícula:{' '}
        <Box display="inline" fontWeight="bold">
          {vehicleInformation?.vehicleLicensePlate}
        </Box>
      </Grid>
      <Grid item md={6} lg={6} xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        Último kilometraje:{' '}
        <Box display="inline" fontWeight="bold">
          {new Intl.NumberFormat('en-DE').format(vehicleInformation?.vehicleMileage)}
        </Box>
      </Grid>
      <Grid item md={6} lg={6} xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        Marca:{' '}
        <Box display="inline" fontWeight="bold">
          {vehicleInformation?.vehicleBrand?.toUpperCase()}
        </Box>
      </Grid>
      <Grid item md={6} lg={6} xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        Año:{' '}
        <Box display="inline" fontWeight="bold">
          {vehicleInformation?.vehicleYear}
        </Box>
      </Grid>
      <Grid item md={6} lg={6} xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
        Modelo:{' '}
        <Box display="inline" fontWeight="bold">
          {vehicleInformation?.vehicleModel}
        </Box>
      </Grid>
      {!isDetailsPage && (
        <Grid item md={6} lg={6} xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
          Nuevo kilometraje ingresado:{' '}
          <Box display="inline" fontWeight="bold">
            {new Intl.NumberFormat('en-DE').format(vehicleInformation?.vehicleNewMileage) || ''}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default VehicleInformation;
