import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, Card, CardContent, CardMedia, Grid, TextField, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { LoadingButton } from '@mui/lab';

/* Project */
import { loginStyles } from 'content/features/authentication';
import { useNavigate } from 'react-router-dom';

/* Public */
// import TCITLogo from '/assets/images/TcitLogo.png';
const SalfaLogo = `${window.location.origin}/images/salfa-rent.jpg`;

function LoginForm(props) {
  const { goToHome, controls, errors, onChangeText, handleLogin, loading } = props;

  const navigate = useNavigate();

  const goToRecovery = () => {
    navigate('/recovery');
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card elevation={2} style={{ margin: '0px 10px' }}>
            <CardMedia image={SalfaLogo} style={{ marginTop: '20px' }} />
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10} textAlign="center">
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    style={{
                      height: 70,
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    image={SalfaLogo}
                    title="TCIT"
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography
                    variant="caption"
                    sx={{ fontSize: '1rem', color: '#007DFF', fontWeight: '600' }}>
                    Inicia sesión
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    autoFocus
                    inputProps={{ 'data-testid': 'text-field-email' }}
                    label="Correo electrónico"
                    id="email"
                    value={controls.email}
                    onChange={onChangeText}
                    style={{ paddingBottom: '8px' }}
                    fullWidth
                    error={!!errors.email.message}
                    helperText={errors.email.message}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    inputProps={{ 'data-testid': 'text-field-password' }}
                    label="Contraseña"
                    onChange={onChangeText}
                    value={controls.password}
                    id="password"
                    fullWidth
                    type="password"
                    error={!!errors.password.message}
                    helperText={errors.password.message}
                  />
                </Grid>
                {controls.error && (
                  <Grid item xs={10} textAlign="center">
                    <Typography style={{ color: 'red' }} align="center" variant="caption">
                      Credenciales inválidas
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={10} textAlign="center" style={{ marginBottom: '20px' }}>
                  <LoadingButton
                    data-testid="button-login"
                    onClick={handleLogin}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '5px' }}
                    loadingPosition="start"
                    startIcon={<LockIcon data-testid="auth-spinner" />}
                    loading={loading}>
                    Ingresar
                  </LoadingButton>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" color="primary">
                    <Button
                      onClick={goToRecovery}
                      style={{ width: '90%' }}
                      variant="outlined"
                      color="secondary">
                      Recuperar contraseña
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  goToHome: PropTypes.func.isRequired,
  controls: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LoginForm.defaultProps = {
  loading: false,
};

export default LoginForm;
