/* eslint-disable complexity */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import VehicleTableItem from './VehicleTableItem';

function VehicleTable(props) {
  const { rows, setNewMileageModal } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table /* sx={{ minWidth: 650 }} */ aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#002854', color: 'white' }}>
            <TableRow>
              <TableCell
                align="center"
                sx={{ color: 'white', fontSize: { md: '18px', sm: '16px', xs: '14px' } }}>
                MARCA
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: 'white', fontSize: { md: '18px', sm: '16px', xs: '14px' } }}>
                MODELO
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: 'white', fontSize: { md: '18px', sm: '16px', xs: '14px' } }}>
                AÑO
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: 'white', fontSize: { md: '18px', sm: '16px', xs: '14px' } }}>
                ÚLTIMO KILOMETRAJE
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: 'white', fontSize: { md: '18px', sm: '16px', xs: '14px' } }}>
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <VehicleTableItem row={row} setNewMileageModal={setNewMileageModal} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && (
        <Typography
          component="div"
          align="center"
          style={{ marginBottom: '7px', marginTop: '7px' }}>
          No se ha encontrado equipo
        </Typography>
      )}
    </>
  );
}

export default VehicleTable;
