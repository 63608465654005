/* eslint-disable complexity */
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { userSelector } from 'content/features/authentication/user/user.selector';
import LoadingModal from 'content/features/search-report/components/LoadingModal';
import PdfModal from 'content/features/search-report/components/PdfModal';
import ReportTable from 'content/features/search-report/components/ReportTable';
import {
  clearReportInformation,
  fetchReport,
  updateActions,
  updateReportSearchData,
} from 'content/features/search-report/searchReport.actions';
import {
  actionsSelector,
  loadersSelector,
  searchDataSelector,
  searchingReportsSelector,
} from 'content/features/search-report/searchReport.selector';
import NewMileageModal from 'content/features/search-vehicle/components/NewMileageModal';
import VehicleTable from 'content/features/search-vehicle/components/VehicleTable';
import {
  changeActions,
  clearVehicleInformation,
  fetchVehicleInformation,
  updateVehicleInformation,
} from 'content/features/search-vehicle/searchVehicle.actions';
import { searchingVehicleSelector } from 'content/features/search-vehicle/searchVehicle.selector';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { objectsSelector } from 'utils/reducer/objects.selector';

function SearchReport() {
  const [licensePlate, setLicensePlate] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isHomePage = location.pathname.split('/').slice(-1)[0] === '';

  const objectsState = useSelector(objectsSelector);
  const searchReportLoaders = useSelector(loadersSelector);
  const searchData = useSelector(searchDataSelector);
  const searchReportActions = useSelector(actionsSelector);
  const user = useSelector(userSelector);
  const searchingVehicle = useSelector(searchingVehicleSelector);
  const searchingReports = useSelector(searchingReportsSelector);
  const vehicleInformation = Object.values(objectsState.vehicleInformation);
  const vehicleReports = Object.values(objectsState.vehicleReports);

  const username = user?.email?.split('@')[0].replace(/[^a-zA-Z0-9 ]/g, '');

  useEffect(() => {
    /* dispatch(clearVehicleInformation());
    dispatch(clearReportInformation()); */
    setLicensePlate('');
  }, [isHomePage]);

  const setNewMileageModal = (boolean) => {
    dispatch(changeActions({ newMileageModal: boolean }));
  };

  const setPdfModal = (boolean) => {
    dispatch(updateActions({ pdfViewModal: boolean }));
  };

  const updateVehicleNewMileage = (newMileage) => {
    dispatch(updateVehicleInformation({ vehicleNewMileage: newMileage }));
  };

  const handleChange = (event) => {
    setLicensePlate(event.target.value);
  };

  const handleClick = () => {
    dispatch(clearVehicleInformation());
    dispatch(clearReportInformation());
    const formattedLicensePlate = licensePlate.trim().toUpperCase();
    dispatch(updateReportSearchData({ licensePlateSearched: formattedLicensePlate }));
    if (isHomePage) {
      dispatch(fetchVehicleInformation({ formattedLicensePlate }));
    } else {
      dispatch(fetchReport({ formattedLicensePlate }));
    }
  };
  return (
    <>
      <Grid
        container
        sx={{
          paddingLeft: { sm: '10px', xs: '10px' },
          paddingmarginRight: { sm: '10px', xs: '10px' },
        }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{ fontSize: '34px', fontWeight: 900, color: '#002854', marginTop: '30px' }}>
            {isHomePage ? 'CHECKLIST DE VENTA' : 'BUSCAR FICHAS'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ marginTop: '10px' }}>
          <Typography sx={{ fontWeight: 'bold', color: '#002854' }}>Ingrese matrícula</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            placeholder="Matrícula vehículo"
            size="small"
            value={licensePlate}
            onChange={handleChange}
            sx={{ marginRight: '20px' }}
          />
          <LoadingButton
            loading={searchingVehicle || searchingReports}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            disabled={licensePlate.length < 1}>
            BUSCAR
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ marginTop: '40px' }}>
          <Typography sx={{ fontWeight: 'bold', color: '#002854' }}>
            {isHomePage ? ' Información del vehículo consultado' : 'Ficha del vehículo consultado'}
          </Typography>
          {isHomePage ? (
            <VehicleTable rows={vehicleInformation} setNewMileageModal={setNewMileageModal} />
          ) : (
            <ReportTable
              rows={vehicleReports}
              licensePlateSearched={searchData.licensePlateSearched}
              userName={username}
            />
          )}
        </Grid>
      </Grid>

      <NewMileageModal
        setNewMileageModal={setNewMileageModal}
        currentMileage={vehicleInformation[0]?.vehicleMileage}
        updateVehicleNewMileage={updateVehicleNewMileage}
        navigate={navigate}
      />
      <LoadingModal openModal={searchReportLoaders.loadingCopyReport} />
      <PdfModal
        openModal={searchReportActions.pdfViewModal}
        currentPdf={searchData.currentPdf}
        setPdfModal={setPdfModal}
        reportVersion={searchData.reportVersion}
        reportId={searchData.reportId}
        licensePlateSearched={searchData.licensePlateSearched}
      />
    </>
  );
}

export default SearchReport;
