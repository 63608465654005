import { createAction } from '@reduxjs/toolkit';
import SearchReportTypes from './searchReport.types';

export const fetchReport = createAction(SearchReportTypes.FETCH_REPORT, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const fetchReportSuccess = createAction(
  SearchReportTypes.FETCH_REPORT_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchReportFail = createAction(SearchReportTypes.FETCH_REPORT_FAIL);

export const clearReportInformation = createAction(SearchReportTypes.CLEAR_REPORT_INFORMATION);

export const clearPreLoadedInformation = createAction(
  SearchReportTypes.CLEAR_PRE_LOADED_INFORMATION,
);

export const copyReportSap = createAction(SearchReportTypes.COPY_REPORT_SAP, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const copyReportSapSuccess = createAction(
  SearchReportTypes.COPY_REPORT_SAP_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const copyReportSapFail = createAction(SearchReportTypes.COPY_REPORT_SAP_FAIL);

export const deleteReportSap = createAction(SearchReportTypes.DELETE_REPORT_SAP, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const deleteReportSapSuccess = createAction(
  SearchReportTypes.DELETE_REPORT_SAP_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const deleteReportSapFail = createAction(SearchReportTypes.DELETE_REPORT_SAP_FAIL);

export const printReportSap = createAction(SearchReportTypes.PRINT_REPORT_SAP, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const printReportSapSuccess = createAction(SearchReportTypes.PRINT_REPORT_SAP_SUCCESS);

export const printReportSapFail = createAction(SearchReportTypes.PRINT_REPORT_SAP_FAIL);

export const updateReportSearchData = createAction(
  SearchReportTypes.UPDATE_REPORT_SEARCH_DATA,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const updateActions = createAction(SearchReportTypes.UPDATE_ACTIONS, (payload) => ({
  payload: {
    ...payload,
  },
}));

const SearchReportActions = {
  fetchReport,
  fetchReportSuccess,
  fetchReportFail,

  clearReportInformation,
  clearPreLoadedInformation,

  copyReportSap,
  copyReportSapSuccess,
  copyReportSapFail,

  deleteReportSap,
  deleteReportSapSuccess,
  deleteReportSapFail,

  printReportSap,
  printReportSapSuccess,
  printReportSapFail,

  updateReportSearchData,

  updateActions,
};

export default SearchReportActions;
