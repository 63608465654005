import { fetchVehicleInformationSuccess } from './searchVehicle.actions';
import { fetchVehicleInformationFail } from './searchVehicle.actions';
import { fetchVehicleInformation } from './searchVehicle.actions';
import { changeActions } from './searchVehicle.actions';

const { createReducer } = require('@reduxjs/toolkit');

const initialState = {
  loaders: {
    page: false,
    update: false,
  },
  actions: {
    newMileageModal: false,
    searchingVehicle: false,
  },
};

const SearchVehicleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeActions, (state, action) => {
      state.actions = {
        ...state.actions,
        ...action.payload,
      };
    })
    .addCase(fetchVehicleInformation, (state) => {
      state.actions.searchingVehicle = true;
    })
    .addCase(fetchVehicleInformationSuccess, (state) => {
      state.actions.searchingVehicle = false;
    })
    .addCase(fetchVehicleInformationFail, (state) => {
      state.actions.searchingVehicle = false;
    });
});

export default SearchVehicleReducer;
