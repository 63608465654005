/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
import { Button, Dialog, DialogContent, Slide, Grid } from '@mui/material';
import React from 'react';
import DownloadPdfButton from './DownloadPdfButton';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function PdfModal(props) {
  const { openModal, currentPdf, setPdfModal, reportVersion, reportId, licensePlateSearched } =
    props;

  const pdfFileName = `Reporte-${licensePlateSearched}-${reportId}-${reportVersion}.pdf`;

  const closeModal = () => {
    setPdfModal(false);
  };
  /* eslint-disable-next-line camelcase */
  const isFileSizeGreaterThan1_5MB = () => {
    // Convert base64 to binary data
    const binaryData = atob(currentPdf);

    // Calculate the size of the binary data in bytes
    const fileSizeInBytes = binaryData.length;

    // Convert bytes to megabytes (MB)
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    return fileSizeInMB > 1.5;
  };

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grid container justifyContent="center" sx={{ paddingTop: '15px' }}>
          {isFileSizeGreaterThan1_5MB() && (
            <DownloadPdfButton base64Data={currentPdf} fileName={pdfFileName} />
          )}
          <Button onClick={closeModal} color="red" variant="outlined" sx={{ marginBottom: '15px' }}>
            Cerrar
          </Button>
          {!isFileSizeGreaterThan1_5MB() && (
            <iframe
              src={`data:application/pdf;base64,${currentPdf}`}
              width="100%"
              height="600"
              title="test-title"
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PdfModal;
