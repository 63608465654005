/* eslint-disable complexity */
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { useDispatch } from 'react-redux';
import { normalizeObj } from 'utils/helper';
import { changeActions, removeObservation, updateReportData } from '../createReport.actions';

function PrincStateTableActions(props) {
  const { item, isDetailsPage } = props;
  const options = !isDetailsPage ? ['Editar', 'Eliminar'] : ['Ver detalle'];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    switch (event.target.id) {
      case 'Eliminar':
        dispatch(
          removeObservation({
            ids: {
              observationsSelected: [item.observation.id],
            },
            objects: {
              reprovedItems: normalizeObj([
                {
                  id: item.observation.id,
                  ...item,
                },
              ]),
            },
          }),
        );
        setAnchorEl(null);
        break;
      case 'Editar':
        dispatch(changeActions({ observationsModal: true }));
        dispatch(updateReportData({ observationModalInfo: item }));
        setAnchorEl(null);
        break;
      case 'Ver detalle':
        dispatch(changeActions({ observationsModal: true }));
        dispatch(updateReportData({ observationModalInfo: item }));
        setAnchorEl(null);
        break;
      default:
        setAnchorEl(null);
    }
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}>
        {options.map((option) => (
          <MenuItem key={option} onClick={handleClose} id={option}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default PrincStateTableActions;
