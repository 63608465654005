/* eslint-disable complexity */
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import ReportTableActions from './ReportTableActions';

function ReportTableItem(props) {
  const { row, licensePlateSearched, userName } = props;

  const formatDate = (dateString) => {
    const dateStringArray = dateString.split('');
    dateStringArray.splice(4, 0, '/');
    dateStringArray.splice(7, 0, '/');
    const formattedDate = dateStringArray.join('');
    return formattedDate;
  };

  return (
    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        align="center"
        sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        {Number(row.reportId)}-{Number(row.reportVersion)}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        {formatDate(row.reportDate)}
      </TableCell>
      <TableCell align="center">
        <ReportTableActions
          item={row}
          licensePlateSearched={licensePlateSearched}
          userName={userName}
        />
      </TableCell>
    </TableRow>
  );
}

export default ReportTableItem;
