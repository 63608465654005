/* eslint-disable complexity */
import { Button, TableCell, TableRow } from '@mui/material';
import React from 'react';

function VehicleTableItem(props) {
  const { row, setNewMileageModal } = props;

  const handleCreateReport = () => {
    setNewMileageModal(true);
    /* navigate('/report/create'); */
  };
  return (
    <TableRow key={row.vehicleBrand} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        align="center"
        sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        {row?.vehicleBrand?.toUpperCase()}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        {row.vehicleModel}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        {row.vehicleYear}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        {row.vehicleMileage}
      </TableCell>
      <TableCell align="center">
        <Button variant="outlined" color="secondary" onClick={handleCreateReport}>
          {' '}
          CREAR FICHA
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default VehicleTableItem;
