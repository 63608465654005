/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress, Dialog, DialogContent, Grid, Slide, Typography } from '@mui/material';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function LoadingModal(props) {
  const { openModal } = props;

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grid container justifyContent="center" sx={{ paddingTop: '15px' }}>
          <Grid container justifyContent="center">
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              ESPERANDO RESPUESTA DE SAP
            </Typography>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginTop: '30px', marginBottom: '20px' }}>
            <CircularProgress />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingModal;
