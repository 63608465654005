import React, { useState } from 'react';

/* React Redux */
import { useSelector, useDispatch } from 'react-redux';

/* Material UI */
import { Grid } from '@mui/material';
import { LoginActions } from 'content/features/authentication';
import { clearReportInformation } from 'content/features/search-report/searchReport.actions';
import { clearVehicleInformation } from 'content/features/search-vehicle/searchVehicle.actions';
import { Footer, NavBar, TemporaryDrawer } from '../../shared';

function Layout(props) {
  const { children, element } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);
  const signedByGoogle = useSelector((state) => state.app.signedByGoogle);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogout = () => {
    dispatch(clearReportInformation());
    dispatch(clearVehicleInformation());
    dispatch(LoginActions.logout({ user, signedByGoogle }));
  };

  return (
    <>
      <NavBar setOpenDrawer={setOpenDrawer} user={user} handleLogout={handleLogout} />
      <div className="content-container">
        <Grid container justifyContent="center" sx={{ paddingTop: '16px' }}>
          <Grid container item md={11} sm={12}>
            {element || children}
          </Grid>
        </Grid>
      </div>
      <TemporaryDrawer openByDefault={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Footer />
    </>
  );
}

export default Layout;
