/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable complexity */
/* eslint-disable no-promise-executor-return */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

function CaptureImage(props) {
  const { openModal, Transition, handleCameraModal, handleFileUpload } = props;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState('');
  const [imgBlob, setImgBlob] = useState(null);
  // eslint-disable-next-line
  const [selectedCamera, setSelectedCamera] = useState('environment');
  const [loadingCamera, setLoadingCamera] = useState(false); // 'environment' para la cámara trasera, 'user' para la cámara frontal

  // Función para iniciar la cámara
  const startCamera = async () => {
    setLoadingCamera(true);
    try {
      const constraints = {
        audio: false,
        video: {
          facingMode: selectedCamera,
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error('Error al acceder a la cámara:', error);
    }
    setLoadingCamera(false);
  };

  const stopCurrentStream = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  // Función para capturar la imagen
  const capture = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    const image = canvas.toDataURL('image/png');
    setCapturedImage(image);

    // // Obtiene el archivo de la imagen capturada
    const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg'));
    setImgBlob(blob);

    // // Crea un objeto FormData y agrega el archivo
    // const formData = new FormData();
    // formData.append('image', blob, 'captured.jpg');

    // // Enviar la imagen al servidor
    // const response = await fetch('/api/upload', {
    //   method: 'POST',
    //   body: formData,
    // });
  };

  // Función para cambiar entre la cámara frontal y la cámara trasera
  /* const toggleCamera = () => {
    setSelectedCamera((prevCamera) => (prevCamera === 'environment' ? 'user' : 'environment'));
  }; */

  const handleCloseCamera = (boolean) => {
    handleCameraModal(boolean);
    setCapturedImage(null);
    stopCurrentStream();
  };

  const handleDeleteCapturedImage = () => {
    setCapturedImage(null);
    if (!videoRef.current) {
      startCamera();
    }
  };

  const confirmCapturedImage = async () => {
    const uuid = uuidv4();
    const file = new File([imgBlob], `Captured-${uuid}.jpg`);
    const event = {
      target: {
        files: {
          0: file,
        },
      },
    };
    handleFileUpload(event);
    setCapturedImage(null);
    stopCurrentStream();
    handleCameraModal(false);
  };

  // Iniciar la cámara al montar el componente y cada vez que cambie la cámara seleccionada
  useEffect(() => {
    startCamera();
  }, [selectedCamera]);

  const isCapturedImage = () => {
    if (capturedImage) {
      return true;
    }
    return false;
  };
  console.log(isCapturedImage());

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grid container justifyContent="center" sx={{ paddingTop: '15px' }}>
          <Grid container justifyContent="center">
            <Box>
              {loadingCamera && (
                <>
                  <Grid container justifyContent="center">
                    <Typography sx={{ fontSize: '24px', marginTop: '75px' }}>
                      Abriendo Cámara{' '}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" sx={{ marginTop: '25px' }}>
                    <CircularProgress />
                  </Grid>
                </>
              )}

              <Box sx={{ display: isCapturedImage() ? 'none' : 'block' }}>
                <video ref={videoRef} autoPlay />
              </Box>

              {capturedImage && (
                <div>
                  <img src={capturedImage} alt="Captured" />
                </div>
              )}
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </Box>
          </Grid>
          <Grid container justifyContent="center" xs={12} spacing={2}>
            {!capturedImage && (
              <Grid item>
                <Button variant="contained" color="secondary" onClick={capture}>
                  Capturar imagen
                </Button>
              </Grid>
            )}
            {capturedImage && (
              <Grid item>
                <Button variant="contained" color="success" onClick={confirmCapturedImage}>
                  CONFIRMAR
                </Button>
              </Grid>
            )}
            {capturedImage && (
              <Grid item>
                <Button variant="outlined" color="secondary" onClick={handleDeleteCapturedImage}>
                  TOMAR NUEVA FOTO
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="outlined" color="error" onClick={() => handleCloseCamera(false)}>
                CERRAR CÁMARA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CaptureImage;
