/* eslint-disable no-plusplus */
import { Button, Typography, Grid } from '@mui/material';
import React from 'react';

function DownloadPdfButton(props) {
  const { base64Data, fileName } = props;
  const handleDownload = () => {
    // Convert base64 to binary data
    const binaryData = atob(base64Data);

    // Create a Uint8Array to hold the binary data
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a download link and trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <Grid container justifyContent="center" sx={{ paddingTop: '15px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
        El documento PDF es muy pesado, por favor descargue el archivo.
      </Typography>
      <Grid container justifyContent="center" sx={{ padding: '15px 0px' }}>
        <Button onClick={handleDownload} variant="contained" color="success">
          Descargar PDF
        </Button>
      </Grid>
    </Grid>
  );
}

export default DownloadPdfButton;
