const { createReducer } = require('@reduxjs/toolkit');
const {
  changeActions,
  fetchMainStatus,
  fetchMainStatusSuccess,
  fetchMainStatusFail,
  fetchObservations,
  fetchObservationsSuccess,
  fetchObservationsFail,
  fetchObservationStatus,
  fetchObservationStatusSuccess,
  fetchObservationStatusFail,
  addObservation,
  removeObservation,
  updateReportData,
  updateObservationModalData,
  fetchVehiclePriceFail,
  fetchVehiclePriceSuccess,
  fetchReportDetails,
  fetchReportDetailsSuccess,
  fetchReportDetailsFail,
  uploadReportInformation,
  uploadReportInformationSuccess,
  uploadReportInformationFail,
  uploadObservationImg,
  uploadObservationImgSuccess,
  uploadObservationImgFail,
} = require('./createReport.actions');

const initialState = {
  loaders: {
    page: false,
    update: false,
  },
  actions: {
    mainStatusModal: false,
    observationsModal: false,
    vehicleNotFoundModal: false,
    newMileageModal: false,
    reportDetailsFetched: false,
    uploadingReportInformationModal: false,
    uploadingImageModal: false,
  },
  reportData: {
    commentary: '',
    observationModalInfo: {
      id: '',
      observationType: null,
      observation: null,
      status: null,
      files: [],
    },
  },
};

const CreateReportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeActions, (state, action) => {
      state.actions = {
        ...state.actions,
        ...action.payload,
      };
    })
    .addCase(fetchMainStatus, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchMainStatusSuccess, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchMainStatusFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchObservations, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchObservationsSuccess, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchObservationsFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchObservationStatus, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchObservationStatusSuccess, (state) => {
      state.loaders.page = false;
    })
    .addCase(addObservation, (state) => {
      state.loaders.update = false;
    })
    .addCase(removeObservation, (state) => {
      state.loaders.update = false;
    })
    .addCase(updateReportData, (state, action) => {
      state.reportData = {
        ...state.reportData,
        ...action.payload,
      };
    })
    .addCase(updateObservationModalData, (state, action) => {
      state.reportData.observationModalInfo = {
        ...state.reportData.observationModalInfo,
        ...action.payload,
      };
    })
    .addCase(fetchObservationStatusFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchVehiclePriceFail, (state, action) => {
      if (!state.actions.vehicleNotFoundModal) {
        state.actions.newMileageModal = action.payload.toggleModal;
      }
    })
    .addCase(fetchVehiclePriceSuccess, (state, action) => {
      state.actions.newMileageModal = action.payload.toggleModal;
    })
    .addCase(fetchReportDetails, (state) => {
      state.loaders.page = true;
      state.actions.reportDetailsFetched = false;
    })
    .addCase(fetchReportDetailsSuccess, (state) => {
      state.loaders.page = false;
      state.actions.reportDetailsFetched = true;
    })
    .addCase(fetchReportDetailsFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(uploadReportInformation, (state) => {
      state.actions.uploadingReportInformationModal = true;
    })
    .addCase(uploadReportInformationFail, (state) => {
      state.actions.uploadingReportInformationModal = false;
    })
    .addCase(uploadReportInformationSuccess, (state) => {
      state.actions.uploadingReportInformationModal = false;
    })
    .addCase(uploadObservationImg, (state) => {
      state.actions.uploadingImageModal = true;
    })
    .addCase(uploadObservationImgSuccess, (state) => {
      state.actions.uploadingImageModal = false;
    })
    .addCase(uploadObservationImgFail, (state) => {
      state.actions.uploadingImageModal = false;
    });
});

export default CreateReportReducer;
