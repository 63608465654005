const BASE = '[SearchReport]';

const SearchReportTypes = {
  FETCH_REPORT: `${BASE} FETCH_REPORT`,
  FETCH_REPORT_SUCCESS: `${BASE} FETCH_REPORT_SUCCESS`,
  FETCH_REPORT_FAIL: `${BASE} FETCH_REPORT_FAIL`,

  CLEAR_REPORT_INFORMATION: `${BASE} CLEAR_REPORT_INFORMATION`,
  CLEAR_PRE_LOADED_INFORMATION: `${BASE} CLEAR_PRE_LOADED_INFORMATION`,

  COPY_REPORT_SAP: `${BASE} COPY_REPORT_SAP`,
  COPY_REPORT_SAP_SUCCESS: `${BASE} COPY_REPORT_SAP_SUCCESS`,
  COPY_REPORT_SAP_FAIL: `${BASE} COPY_REPORT_SAP_FAIL`,

  DELETE_REPORT_SAP: `${BASE} DELETE_REPORT_SAP`,
  DELETE_REPORT_SAP_SUCCESS: `${BASE} DELETE_REPORT_SAP_SUCCESS`,
  DELETE_REPORT_SAP_FAIL: `${BASE} DELETE_REPORT_SAP_FAIL`,

  PRINT_REPORT_SAP: `${BASE} PRINT_REPORT_SAP`,
  PRINT_REPORT_SAP_SUCCESS: `${BASE} PRINT_REPORT_SAP_SUCCESS`,
  PRINT_REPORT_SAP_FAIL: `${BASE} PRINT_REPORT_SAP_FAIL`,

  UPDATE_REPORT_SEARCH_DATA: `${BASE} UPDATE_REPORT_SEARCH_DATA`,
  UPDATE_ACTIONS: `${BASE} UPDATE_ACTIONS`,
};

export default SearchReportTypes;
