/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import { newMileageModalSelector } from '../searchVehicle.selector';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function NewMileageModal(props) {
  const { setNewMileageModal, currentMileage, updateVehicleNewMileage, navigate } = props;
  const openNewMileageModal = useSelector(newMileageModalSelector);
  const [newMileage, setNewMileage] = useState('');
  const [newMileageError, setNewMileageError] = useState(false);

  const closeNewMileageModal = () => {
    setNewMileageModal(false);
  };

  const confirmNewMileageModal = () => {
    if (Number(newMileage) < Number(currentMileage)) {
      setNewMileageError(true);
      defaultErrorToast({
        message: `El nuevo kilometraje no puede ser menor a ${new Intl.NumberFormat('es-CL').format(
          currentMileage,
        )}`,
      });
    } else {
      updateVehicleNewMileage(newMileage);
      setNewMileageModal(false);
      navigate('/report/create');
    }
  };

  const handleChange = (event) => {
    if (newMileageError) {
      setNewMileageError(false);
    }
    setNewMileage(event.target.value);
  };

  return (
    <Dialog
      open={openNewMileageModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => closeNewMileageModal()}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Grid container justifyContent="center" sx={{ paddingTop: '25px' }}>
          <Grid container justifyContent="center" item sx={12}>
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              INGRESAR NUEVO KILOMETRAJE
            </Typography>
          </Grid>
          <Grid container justifyContent="center" item sx={12}>
            <Typography>
              Último kilometraje: {new Intl.NumberFormat('es-CL').format(currentMileage)}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <TextField
              error={newMileageError}
              sx={{ paddingTop: '20px', paddingBottom: '15px' }}
              placeholder="Nuevo kilometraje"
              value={newMileage}
              onChange={handleChange}
              type="number"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: '30px' }}>
        <Grid container item sm={6} md={6} lg={6} justifyContent="center">
          <Button onClick={closeNewMileageModal} variant="outlined" color="error">
            Cancelar
          </Button>
        </Grid>
        <Grid container item sm={6} md={6} lg={6} justifyContent="center">
          <Button onClick={confirmNewMileageModal} variant="contained" color="secondary">
            Siguiente
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default NewMileageModal;
