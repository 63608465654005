export const newMileageModalSelector = (state) => state.searchVehicle.actions.newMileageModal;

export const searchingVehicleSelector = (state) => state.searchVehicle.actions.searchingVehicle;

const SearchVehicleSelector = {
  newMileageModal: newMileageModalSelector,
  searchingVehicle: searchingVehicleSelector,
};

export default SearchVehicleSelector;
