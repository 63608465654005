/* eslint-disable no-unused-vars */
/* eslint-disable complexity */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PrincStateTable from './PrincStateTable';
import { updateReportData, updateVehicleInformationCreateReport } from '../createReport.actions';

function PrincStateDescription(props) {
  const {
    accordionTitle,
    expand,
    mainState,
    observationsSelected,
    typeId,
    hasMainState,
    onlyDetails,
    isCommentaryItem,
    reportData,
    isDetailsPage,
    photosColumn,
    vehicleInformation,
  } = props;

  const [globalExpand, setGlobalExpand] = useState(expand);
  const dispatch = useDispatch();

  const observations = observationsSelected
    ? observationsSelected.filter((item) => item.observation.type_id === typeId)
    : [];

  useEffect(() => {
    setGlobalExpand(expand);
  }, [expand]);

  const handleClick = () => {
    setGlobalExpand(!globalExpand);
  };

  const handleCommentaryChange = (event) => {
    const newCommentary = {
      commentary: event.target.value,
    };
    dispatch(updateReportData(newCommentary));
    dispatch(updateVehicleInformationCreateReport({ comments: event.target.value }));
  };

  return (
    <Accordion sx={{ width: '100%', margin: '7px 0px' }} expanded={globalExpand === true}>
      <AccordionSummary
        sx={{ marginTop: '10px' }}
        expandIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>{accordionTitle}</Typography>
      </AccordionSummary>
      {!isCommentaryItem && (
        <AccordionDetails>
          {hasMainState && (
            <Typography>
              Estado:{' '}
              <Box display="inline" fontWeight="bold">
                {mainState?.length ? mainState : 'No asignado'}
              </Box>
            </Typography>
          )}
          <Typography sx={{ marginBottom: '5px', marginTop: '20px', fontWeight: 'bold' }}>
            Observación:
          </Typography>
          {observations.length > 0 && (
            <PrincStateTable
              rows={observations}
              hasMainState={hasMainState}
              onlyDetails={onlyDetails}
              isDetailsPage={isDetailsPage}
              photosColumn={photosColumn}
            />
          )}
          {observations.length <= 0 && (
            <Typography
              component="div"
              align="center"
              style={{ marginBottom: '7px', marginTop: '7px' }}>
              No se han añadido observaciones
            </Typography>
          )}
        </AccordionDetails>
      )}
      {isCommentaryItem && (
        <Grid container justifyContent="center" sx={{ padding: '15px 0px' }}>
          <TextField
            disabled={isDetailsPage}
            multiline
            placeholder="Ingrese sus comentarios aquí"
            minRows={6}
            onChange={handleCommentaryChange}
            value={/* reportData.commentary */ vehicleInformation?.comments || ''}
            sx={{
              minWidth: '85%',
              borderRadius: '40px',
            }}
          />
        </Grid>
      )}
    </Accordion>
  );
}

export default PrincStateDescription;
