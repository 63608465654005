/* eslint-disable complexity */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import PrincStateTableItem from './PrincStateTableItem';

function PrincStateTable(props) {
  const { rows, hasMainState, onlyDetails, isDetailsPage, photosColumn } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table /* sx={{ minWidth: 650 }} */ aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ width: '25%', backgroundColor: '#002854', color: 'white' }}>
                Descripción
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: '25%', backgroundColor: '#002854', color: 'white' }}>
                Detalle
              </TableCell>
              {photosColumn && (
                <TableCell align="center" sx={{ backgroundColor: '#002854', color: 'white' }}>
                  Fotos
                </TableCell>
              )}
              {!hasMainState && !onlyDetails && (
                <TableCell sx={{ backgroundColor: '#002854', color: 'white' }} align="center">
                  Valor
                </TableCell>
              )}
              <TableCell align="center" sx={{ backgroundColor: '#002854', color: 'white' }}>
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <PrincStateTableItem
                row={row}
                hasMainState={hasMainState}
                onlyDetails={onlyDetails}
                isDetailsPage={isDetailsPage}
                photosColumn
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && (
        <Typography
          component="div"
          align="center"
          style={{ marginBottom: '7px', marginTop: '7px' }}>
          No se han añadido observaciones
        </Typography>
      )}
    </>
  );
}

export default PrincStateTable;
