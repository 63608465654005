/* eslint-disable complexity */
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import PrincStateTableActions from './PrincStateTableActions';

function PrincStateTableItem(props) {
  const { row, hasMainState, onlyDetails, isDetailsPage, photosColumn } = props;
  return (
    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        align="center"
        sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
        {row.observation.description}
      </TableCell>
      <TableCell align="center" sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
        {row.status.description}
      </TableCell>
      {photosColumn && (
        <TableCell align="center" sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
          {row.files.length}
        </TableCell>
      )}
      {!hasMainState && !onlyDetails && (
        <TableCell align="center" sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
          {new Intl.NumberFormat('en-DE').format(row.status.value)}
        </TableCell>
      )}
      <TableCell align="center" sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}>
        <PrincStateTableActions item={row} isDetailsPage={isDetailsPage} />
      </TableCell>
    </TableRow>
  );
}

export default PrincStateTableItem;
