const BASE = '[SearchVehicle]';

const SearchVehicleTypes = {
  FETCH_VEHICLE_INFORMATION: `${BASE} FETCH_VEHICLE_INFORMATION`,
  FETCH_VEHICLE_INFORMATION_SUCCESS: `${BASE} FETCH_VEHICLE_INFORMATION_SUCCESS`,
  FETCH_VEHICLE_INFORMATION_FAIL: `${BASE} FETCH_VEHICLE_INFORMATION_FAIL`,

  CLEAR_VEHICLE_INFORMATION: `${BASE} CLEAR_VEHICLE_INFORMATION`,

  CHANGE_ACTIONS: `${BASE} CHANGE_ACTIONS`,

  UPDATE_VEHICLE_INFORMATION: `${BASE} UPDATE_VEHICLE_INFORMATION`,
};

export default SearchVehicleTypes;
