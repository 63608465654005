/* Reducers */
import { CounterReducer } from 'content/features/counter';
import AppReducer from 'content/app.reducer';
import { LoginReducer, RecoveryReducer, RegisterReducer } from 'content/features/authentication';
import { UserTasksReducer } from 'content/features/tasks';
import CreateReportReducer from 'content/features/create-report/createReport.reducer';
import SearchVehicleReducer from 'content/features/search-vehicle/searchVehicle.reducer';
import SearchReportReducer from 'content/features/search-report/searchReport.reducer';

const MainReducer = {
  app: AppReducer,
  login: LoginReducer,
  register: RegisterReducer,
  counter: CounterReducer,
  recovery: RecoveryReducer,
  userTasks: UserTasksReducer,
  createReport: CreateReportReducer,
  searchVehicle: SearchVehicleReducer,
  searchReport: SearchReportReducer,
};

export default MainReducer;
