import React from 'react';
import PropTypes from 'prop-types';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Project
// import Logo from '/assets/images/TcitLogo_123x50.png';
import navbarStyles from './navbar.styles';

const SalfaNavbarLogo = `${window.location.origin}/images/salfa-rent_213x57.jpg`;

function NavBar(props) {
  const { setOpenDrawer, handleLogout, user } = props;
  const navigate = useNavigate();

  function sessionOption() {
    if (user) {
      return (
        <Button onClick={() => handleLogout()} color="inherit" sx={navbarStyles.boldText}>
          Cerrar sesión
        </Button>
      );
    }

    return (
      <Button onClick={() => navigate('/login')} color="inherit">
        Iniciar sesión
      </Button>
    );
  }

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <IconButton
          data-testid="toolbar-menu-button"
          onClick={() => setOpenDrawer(true)}
          edge="start"
          sx={navbarStyles.menuButton}
          color="inherit"
          aria-label="menu">
          <MenuIcon />
        </IconButton>

        <Button onClick={() => navigate('/')}>
          <img style={{ height: '50px' }} src={SalfaNavbarLogo} alt="" />
        </Button>

        <Typography variant="h6" sx={navbarStyles.title} />

        {sessionOption()}
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
