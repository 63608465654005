/* eslint-disable complexity */
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { fClpCurrency, filterStatusByTypeId } from 'utils/functions';

function ReportSummary(props) {
  const {
    mainStatusSelected,
    vehiclePrice,
    observationsSelected,
    updateVehicleInformation,
    vehicleVBaja,
  } = props;
  const rows = [
    { itemName: 'Pintura', type_id: 'P' },
    { itemName: 'Tapiz', type_id: 'T' },
    { itemName: 'Neumáticos', type_id: 'N' },
    { itemName: 'Mecánica', type_id: 'M' },
    { itemName: 'Estructura', type_id: 'E' },
    { itemName: 'Parabrisas', type_id: 'B' },
    { itemName: 'Accesorios', type_id: 'A' },
  ];

  const mainStatusDiscounts = mainStatusSelected.reduce(
    (partialSum, a) => partialSum + (a ? a.value : 0),
    0,
  );

  const observationsDiscounts = observationsSelected.reduce(
    (partialSum, a) => partialSum + (a ? a.status.value : 0),
    0,
  );

  const calcTotalDiscounts = (typeId, arrayToFilter, observationsArrayToFilter) => {
    const mainStatusDiscount = arrayToFilter.find((item) => item.type_id === typeId)
      ? arrayToFilter.find((item) => item.type_id === typeId).value
      : 0;

    const observationsDiscount = observationsArrayToFilter
      ? observationsArrayToFilter
          .filter((item) => item.observation.type_id === typeId)
          .reduce((partialSum, a) => partialSum + (a ? a.status.value : 0), 0)
      : 0;

    const total = mainStatusDiscount + observationsDiscount;

    return total;
  };

  const totalDiscounts = mainStatusDiscounts + observationsDiscounts;

  useEffect(() => {
    updateVehicleInformation(totalDiscounts);
  }, [totalDiscounts]);

  return (
    <>
      <Typography sx={{ marginTop: '35px', fontSize: '22px', fontWeight: 'bold' }}>
        Resumen
      </Typography>
      <Card variant="outlined" sx={{ margin: '5px 0px 10px 0px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="summary table">
            <TableHead>
              <TableRow sx={{ background: '#002854' }}>
                <TableCell align="center" sx={{ width: '33%', fontSize: '16px', color: 'white' }}>
                  ASPECTO
                </TableCell>
                <TableCell align="center" sx={{ fontSize: '16px', color: 'white' }}>
                  ESTADO
                </TableCell>
                <TableCell align="center" sx={{ fontSize: '16px', color: 'white' }}>
                  VALOR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item) => (
                <TableRow>
                  <TableCell align="center" sx={{ width: '33%' }}>
                    {item.itemName}
                  </TableCell>
                  <TableCell align="center">
                    {filterStatusByTypeId(item.type_id, mainStatusSelected)[0]?.description}
                  </TableCell>
                  <TableCell align="center">
                    ${' '}
                    {fClpCurrency(
                      calcTotalDiscounts(item.type_id, mainStatusSelected, observationsSelected),
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  TOTAL DESCUENTOS
                </TableCell>
                <TableCell align="center"> </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  $ {fClpCurrency(totalDiscounts)}
                </TableCell>
              </TableRow>
              {(Boolean(vehiclePrice) || Boolean(vehicleVBaja)) && (
                <TableRow>
                  <TableCell align="center">Precio de lista SAP</TableCell>
                  <TableCell align="center"> </TableCell>
                  <TableCell align="center">
                    $ {fClpCurrency(vehiclePrice || vehicleVBaja)}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  PRECIO DE VENTA PRESUPUESTO
                </TableCell>
                <TableCell align="center"> </TableCell>
                <TableCell align="center">
                  $ {fClpCurrency((vehiclePrice || vehicleVBaja || 0) - totalDiscounts)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default ReportSummary;
